import { Component } from 'react';
import './HomeScreen.css';

interface IHomeScreenProps {
  previousName: string;
  viewLobbiesClicked: (newName: string) => void;
}

interface IHomeScreenState {
  name: string;
}

class HomeScreen extends Component<IHomeScreenProps, IHomeScreenState> {
  constructor(props: IHomeScreenProps) {
    super(props);
    this.state = {
      name: this.props.previousName,
    };
  }

  public render() {
    return (
      <div className="home-screen">
        <h1>Tens &amp; Twos</h1>
        <div className="name-input">
          <label htmlFor="user-name">Your Name</label>
          <input
            type="text"
            name="user-name"
            onChange={this.onNameChange}
            value={this.state.name}
          />
        </div>
        <button
          disabled={this.state.name === ''}
          className="btn play-btn"
          onClick={this.viewLobbiesClicked}
        >
          <span>Play</span>
        </button>
      </div>
    );
  }

  private viewLobbiesClicked = () => {
    this.props.viewLobbiesClicked(this.state.name);
  };

  private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, name: event.target.value });
  };
}

export default HomeScreen;
