import { IVisibleGameState } from '../Models/StateModels';
import { ISourcedCard, PlayerHand, ICard } from '../Models/CardModels';

export class ClientGameService {
  public getPredictedGameState(
    currentState: IVisibleGameState,
    play: ISourcedCard[]
  ): IVisibleGameState {
    if (play.length === 0) {
      return currentState;
    }
    const handPlays = play.filter((x) => x.source === PlayerHand.HAND);
    const visiblePlays = play.filter((x) => x.source === PlayerHand.VISIBLE);
    const hiddenPlays = play.filter((x) => x.source === PlayerHand.HIDDEN);
    return {
      ...currentState,
      playedCards: [...currentState.playedCards, ...play.map((x) => x.card)],
      isClientState: true,
      lastPlayedCard: play.slice(-1)[0]?.card,
      player: {
        ...currentState.player,
        isTurn: false,
        handCards: currentState.player.handCards.filter(
          (x) =>
            !handPlays.find(
              (pl) =>
                pl.source === PlayerHand.HAND && this.cardEquals(pl.card, x)
            )
        ),
        visibleCards: currentState.player.visibleCards.filter(
          (x) =>
            !visiblePlays.find(
              (pl) =>
                pl.source === PlayerHand.VISIBLE && this.cardEquals(pl.card, x)
            )
        ),
        hiddenCards: currentState.player.hiddenCards.filter(
          (x) =>
            !hiddenPlays.find(
              (pl) =>
                pl.source === PlayerHand.HIDDEN && x.cardId === pl.card.cardId
            )
        ),
      },
    };
  }
  public getPredictedGameStateAfterSwap(
    currentState: IVisibleGameState,
    card1: ISourcedCard,
    card2: ISourcedCard
  ): IVisibleGameState {
    if (card1.source === card2.source) {
      return currentState;
    }
    if (
      card1.source === PlayerHand.HIDDEN ||
      card2.source === PlayerHand.HIDDEN
    ) {
      return currentState;
    }

    const handCard = card1.source === PlayerHand.HAND ? card1 : card2;
    const visibleCard = card1.source === PlayerHand.VISIBLE ? card1 : card2;

    const handCardIndex = currentState.player.handCards.findIndex((x) =>
      this.cardEquals(x, handCard.card)
    );
    const visibleCardIndex = currentState.player.visibleCards.findIndex((x) =>
      this.cardEquals(x, visibleCard.card)
    );
    if (visibleCardIndex === -1 || handCardIndex === -1) {
      return currentState;
    }
    const newHandCards = currentState.player.handCards.slice();
    newHandCards[handCardIndex] = visibleCard.card;
    const newVisibleCards = currentState.player.visibleCards.slice();
    newVisibleCards[visibleCardIndex] = handCard.card;
    return {
      ...currentState,
      player: {
        ...currentState.player,
        handCards: newHandCards,
        visibleCards: newVisibleCards,
      },
    };
  }

  getPredictedGameStateAfterReorderingHandCards(
    visibleGameState: IVisibleGameState,
    cards: ICard[]
  ): IVisibleGameState {
    return {
      ...visibleGameState,
      player: {
        ...visibleGameState.player,
        handCards: cards,
      },
    };
  }

  private cardEquals(a: ICard, b: ICard): boolean {
    return a.deckId === b.deckId && a.suit === b.suit && a.value === b.value;
  }
}
