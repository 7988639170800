import { Component } from 'react';
import OpponentCard from '../OpponentCard/OpponentCard';
import OpponentHiddenCard from '../OpponentHiddenCard/OpponentHiddenCard';
import { ICard } from '../../../Models/CardModels';
import { IUnrevealedCard } from '../../../Models/StateModels';

export interface IOpponentTableCardsProps {
  visibleCards: ICard[];
  hiddenCards: IUnrevealedCard[];
}

class OpponentTableCards extends Component<IOpponentTableCardsProps> {
  public render() {
    const { hiddenCards, visibleCards } = this.props;
    const hiddenCardsShown = hiddenCards.slice(
      0,
      Math.max(hiddenCards.length - visibleCards.length, 0)
    );
    return (
      <div className="table-cards">
        {visibleCards.map((c) => (
          <OpponentCard key={c.cardId} card={c} />
        ))}
        {hiddenCardsShown.map((item) => (
          <OpponentHiddenCard key={item.cardId} cardId={item.cardId} />
        ))}
      </div>
    );
  }
}

export default OpponentTableCards;
