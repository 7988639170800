import { Component } from 'react';
import { isNotNull } from '../../../functions';
import { ICard } from '../../../Models/CardModels';
import { IUnrevealedCard } from '../../../Models/StateModels';
import Card from '../Card/Card';
import './Pile.css';

interface IPileProps {
  lastPlayedCard: ICard | IUnrevealedCard | undefined;
  playedCards: (ICard | IUnrevealedCard)[];
}

class Pile extends Component<IPileProps> {
  public render() {
    const { lastPlayedCard, playedCards } = this.props;
    const top4Slice = [...playedCards].slice(-4).reverse().slice(1).reverse();
    const remaining = [...playedCards].slice(4).map((card, i) => (
      <div key={i} className="pile-card-wrapper pile-card-wrapper-hidden ">
        <Card card={{ cardId: card.cardId }} />
      </div>
    ));
    const top3Slice: (ICard | IUnrevealedCard)[] = [
      ...top4Slice,
      lastPlayedCard,
    ]
      .filter(isNotNull)
      .slice(-3);
    const burnOpportunity =
      top3Slice.length === 3 &&
      top3Slice
        .map((x) => 'value' in x && x.value)
        .every((x) => 'value' in top3Slice[0] && x === top3Slice[0].value);

    const top4 = top4Slice.map((card, i) => (
      <div key={i + remaining.length} className="pile-card-wrapper">
        {' '}
        <Card hasFlame={burnOpportunity} card={card} />
      </div>
    ));

    return (
      <div className="pile">
        <div className="pile-card-container">
          {remaining}
          {top4}
          {lastPlayedCard && (
            <Card card={lastPlayedCard} hasFlame={burnOpportunity} />
          )}
        </div>
      </div>
    );
  }
}

export default Pile;
