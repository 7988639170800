export const range = (size: number) => Array.from(Array(size).keys());
export const generateUUID = () => {
  let d = new Date().getTime();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const isEmoji = (candidate: string | undefined) => {
  if (!candidate) {
    return false;
  }
  const emojiRegex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return emojiRegex.test(candidate);
};

export const getOrdinal: (position: number) => 'st' | 'nd' | 'rd' | 'th' = (
  position: number
) => {
  const positionAsStr = position.toString();
  const lastDigit = positionAsStr[positionAsStr.length - 1];
  switch (lastDigit) {
    case '1':
      return 'st';
    case '2':
      return 'nd';
    case '3':
      return 'rd';
    default:
      return 'th';
  }
};

export const isNotNull = <T>(arg: T): arg is Exclude<T, null | undefined> =>
  arg !== null && arg !== undefined;

/**
 * Creates groupings of items which have the same key, when thet are adjacent to eachother.
 * groupedWhenInOrder([1,1,1,2,3,3,2], x=>x)
 * => [
 *    {key: 1, items: [1,1,1]},
 *    {key: 2, items: [2]},
 *    {key: 3, items: [3,3]},
 *    {key: 2, items: [2]}
 * ]
 * @param arr
 * @param keySelector
 * @returns
 */
export const groupedWhenInOrder = <T, TK>(
  arr: T[],
  keySelector: (item: T) => TK
) => {
  const finalGroup: { key: TK; items: T[] }[] = [];

  for (const item of arr) {
    const key = keySelector(item);
    let lastGroup = finalGroup[finalGroup.length - 1];
    if (!lastGroup) {
      lastGroup = { key, items: [] };
      finalGroup.push(lastGroup);
    }
    if (lastGroup.key === key) {
      lastGroup.items.push(item);
    } else {
      finalGroup.push({ key, items: [item] });
    }
  }
  return finalGroup;
};
