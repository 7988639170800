import { Component } from 'react';
import { IUnrevealedCard } from '../../../Models/StateModels';
import Card from '../Card/Card';
import './Deck.css';
interface IDeckProps {
  deckRemaining: IUnrevealedCard[];
}

class Deck extends Component<IDeckProps> {
  public render() {
    const { deckRemaining } = this.props;
    const deckRemainingCount = deckRemaining.length;
    const deckLess1 = [...deckRemaining];
    deckLess1.pop();
    const top10Deck = deckLess1.slice(0, 10);
    const remainingDeck = deckLess1.slice(10);
    const deckDards = top10Deck.map((card, i) => (
      <div
        style={{
          zIndex: i,
          transform: 'translateX(-' + (deckRemainingCount - i) * 0.5 + 'vmin)',
        }}
        key={i}
        className="deck-card-wrapper"
      >
        <Card card={card} />
      </div>
    ));
    const tinyDeckCards = remainingDeck.map((card, i) => (
      <div
        style={{
          zIndex: i,
          transform: 'translateX(-' + (deckRemainingCount - i) * 0.5 + 'vmin)',
        }}
        key={i}
        className="deck-card-wrapper"
      >
        <Card card={card} />
      </div>
    ));
    return (
      <div className="deck">
        <Card card={{ cardId: 'invisibleDeckCard' }} invisible={true} />
        {deckDards}
        {tinyDeckCards}
      </div>
    );
  }
}

export default Deck;
