import { Component } from 'react';
import './PlayHistory.css';
import { IVisiblePlay, IVisibleOpponent, IVisiblePlayer, PlayResult } from '../../../Models/StateModels';
import { CardValue, ICard } from '../../../Models/CardModels';

interface IPlayHistoryProps {
    playHistory: IVisiblePlay[];
    opponents: IVisibleOpponent[];
    player: IVisiblePlayer;
}

class PlayHistory extends Component<IPlayHistoryProps> {


    public render() {
        const { playHistory } = this.props;

        return (
            <div className='play-history'>
                {playHistory.length > 0 ?
                    this.renderPlay(playHistory[playHistory.length - 1])
                    : null
                }
            </div>
        );
    }

    private renderPlay(play: IVisiblePlay) {
        const name = play.playerIndex === this.props.player.playerIndex
            ? 'You'
            : (this.props.opponents.find(x => x.playerIndex === play.playerIndex) || { name: 'someone' }).name;
        let action = '';
        if (play.playedCards.length) {
            action = `played ${this.describeCards(play.playedCards)} `;
        }
        if (play.playResult === PlayResult.PICKED_UP) {
            if (action) { action += 'and '; }
            action += 'picked up';
        } else if (play.playResult === PlayResult.BURNT) {
            if (action) { action += 'and '; }
            action += 'burnt the pile';
        } else if(play.playResult === PlayResult.FORFEIT){
            action = 'forfeited!';
        }
        return <p >{`${name} ${action}`}</p>;
    }

    private describeCards(cards: ICard[]) {
        let desc = '';
        const c = cards[0];
        if (cards.length === 1) {
            if (c.value === CardValue.ACE || c.value === CardValue.EIGHT) {
                desc = 'an ';
            } else {
                desc = 'a ';
            }
        } else {
            desc = `${cards.length} `;
        }
        switch (c.value) {

            case CardValue.TWO:
                desc += 'two';
                break;
            case CardValue.THREE:
                desc += 'three';
                break;
            case CardValue.FOUR:
                desc += 'four';
                break;
            case CardValue.FIVE:
                desc += 'five';
                break;
            case CardValue.SIX:
                desc += 'six';
                break;
            case CardValue.SEVEN:
                desc += 'seven';
                break;
            case CardValue.EIGHT:
                desc += 'eight';
                break;
            case CardValue.NINE:
                desc += 'nine';
                break;
            case CardValue.TEN:
                desc += 'ten';
                break;
            case CardValue.JACK:
                desc += 'jack';
                break;
            case CardValue.QUEEN:
                desc += 'queen';
                break;
            case CardValue.KING:
                desc += 'king';
                break;
            case CardValue.ACE:
                desc += 'ace';
                break;
        }
        if (cards.length > 1) {
            if (c.value === CardValue.SIX) {
                desc += 'es';
            } else {
                desc += 's';
            }
        }
        return desc;
    }
}


export default PlayHistory;
