import { Component } from 'react';
import { ICard } from '../../../Models/CardModels';
import './OpponentCard.css';
import { loadCard } from '../CardLoader/CardLoader';

export interface IOpponentCardProps {
  card: ICard;
}

class OpponentCard extends Component<IOpponentCardProps> {
  public render() {
    return (
      <div
        key={this.props.card.cardId}
        // layoutId={this.props.card.cardId}
        // layout="position"
      >
        {loadCard(this.props.card)}
      </div>
    );
  }
}

export default OpponentCard;
