import { ISourcedCard } from '../../../Models/CardModels';
import DraggableCard from '../DraggableCard/DraggableCard';
import './DraggableCardGroup.css';
import GrabAllImg from './grab-all.png';
import { motion, PanInfo, useDragControls } from 'framer-motion';
import { ISourcedCardDragTarget } from '../../../Models/UIModels';

const DraggableCardGroup = ({
  cards,
  onDragEnd,
  onDragging,
  setDragTarget,
}: {
  cards: ISourcedCard[];
  onDragEnd: (c: ISourcedCard[], info: PanInfo) => void;
  onDragging: (cards: ISourcedCard[], info: PanInfo) => void;
  setDragTarget(playAreaDragTarget: ISourcedCardDragTarget): void;
}) => {
  const controls = useDragControls();
  return (
    <motion.div
      className="selectable-card-group"
      drag
      onDragEnd={(_, info) => onDragEnd(cards, info)}
      onDrag={(_, info) => onDragging(cards, info)}
      dragSnapToOrigin
      dragMomentum={false}
      dragControls={controls}
      whileDrag={{
        zIndex: 1000,
        scale: 1.1,
      }}
    >
      {cards.length > 1 && (
        <img draggable="false" alt="Grab all cards in group" src={GrabAllImg} />
      )}
      <div className="cards">
        {cards.map((c) => (
          <DraggableCard
            card={c}
            onDragEnd={(info) => onDragEnd([c], info)}
            onDragging={(info) => onDragging([c], info)}
            setDragTarget={setDragTarget}
            key={c.card.cardId}
          ></DraggableCard>
        ))}
      </div>
    </motion.div>
  );
};

export default DraggableCardGroup;
