import { Component } from 'react';
import { IVisibleOpponent } from '../../Models/StateModels';
import Opponent from './Opponent';
import './Opponent.css';
import { IChatMessage } from '../../Models/LobbyModels';

export interface IDisplayedOpponent {
  opponent: IVisibleOpponent;
  lastSentMessage: IChatMessage | undefined;
}

export interface IOpponentsProps {
  opponents: IDisplayedOpponent[];
  playerIndex: number;
}

class Opponents extends Component<IOpponentsProps> {
  public render() {
    const { opponents, playerIndex } = this.props;
    const numPlayers = opponents.length + 1;
    // Reorder based on where you are
    const oppVisualIndex = (opp: IVisibleOpponent) =>
      (opp.playerIndex + (numPlayers - playerIndex - 1)) % numPlayers;
    const sortedOpponents = [...opponents].sort(
      (a, b) => oppVisualIndex(a.opponent) - oppVisualIndex(b.opponent)
    );
    return (
      <section className="opponents">
        <div className="opponents-list">
          {sortedOpponents.map((opponent, i) => (
            <Opponent
              lastSentMessage={opponent.lastSentMessage}
              key={i}
              opponent={opponent.opponent}
            />
          ))}
        </div>
      </section>
    );
  }
}

export default Opponents;
