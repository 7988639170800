import { Component } from 'react';
import './Modal.css';

interface IModalProps {
  header: string;
  message: string;
  buttons: React.ReactNode;
}

export default class Modal extends Component<IModalProps> {
  public render() {
    const { header, message, buttons } = this.props;
    return (
      <article className="modal">
        <div className="modal-content">
          <section className="modal-header">
            <h2>{header}</h2>
          </section>
          <section className="modal-body">{message}</section>
          <section className="modal-buttons">{buttons}</section>
        </div>
      </article>
    );
  }
}
