import { Component } from 'react';
import classNames from 'classnames';

interface IErrorProps {
  error: string;
  removeError: () => void;
}
interface IErrorState {
  shouldFade: boolean;
  applied: boolean;
}

class Error extends Component<IErrorProps, IErrorState> {
  state = { shouldFade: false, applied: false };
  public render() {
    const { error, removeError } = this.props;
    return (
      <div
        className={classNames('error', this.state.shouldFade && 'error-fade')}
      >
        <p>{this.prepareError(error)}</p>
        <button onClick={removeError}>✕</button>
      </div>
    );
  }

  public componentDidUpdate() {
    if (this.state.shouldFade && !this.state.applied) {
      this.setState({ applied: true });
      setTimeout(() => this.props.removeError(), 2000);
    }
  }

  public componentDidMount() {
    setTimeout(() => this.setState({ shouldFade: true }), 1000);
  }

  private prepareError(err: string) {
    const index = err.indexOf('HubException:');
    if (index > -1) {
      return err.substr(index + 'HubException:'.length).trim();
    }
    return err.trim();
  }
}

export default Error;
