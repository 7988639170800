import {
  Action,
  ActionWithPayload,
  DispatchWithMiddleWare,
  IThunk,
} from '../definitions';
import { appActions } from './AppActions';
import { gameActions } from './GameActions';
import {
  IVisibleLobby,
  ICreateLobbyArgs,
  IUpdateLobbyArgs,
  IChatMessage,
} from '../Models/LobbyModels';
import { IGameConfig } from '../Models/GameSetupModels';

export enum LobbyActionsEnum {
  LOBBY_STATE_SET_CURRENT_LOBBY = 'LOBBY_STATE_SET_CURRENT_LOBBY',
  LOBBY_STATE_SET_AVAILABLE_LOBBIES = 'LOBBY_STATE_SET_AVAILABLE_LOBBIES',
  LOBBY_STATE_SET_LOBBY_ADMIN_TOKEN = 'LOBBY_STATE_SET_LOBBY_ADMIN_TOKEN',
  LOBBY_STATE_CLEAR_ALL = 'LOBBY_STATE_CLEAR_ALL',
  LOBBY_STATE_CREATE_LOBBY = 'LOBBY_STATE_CREATE_LOBBY',
  LOBBY_STATE_UPDATE_LOBBY = 'LOBBY_STATE_UPDATE_LOBBY',
  LOBBY_STATE_JOIN = 'LOBBY_STATE_JOIN',
  LOBBY_STATE_LEAVE = 'LOBBY_STATE_LEAVE',
  LOBBY_STATE_NEW_GAME_AVAILABLE = 'LOBBY_STATE_NEW_GAME_AVAILABLE',
  LOBBY_STATE_START_NEW_GAME = 'LOBBY_STATE_NEW_CREATED_GAME',
  LOBBY_STATE_NEW_CHAT_MESSAGE = 'LOBBY_STATE_NEW_CHAT_MESSAGE',
}
export class SetCurrentLobbyAction extends ActionWithPayload<
  LobbyActionsEnum,
  IVisibleLobby | undefined
> {
  public readonly type = LobbyActionsEnum.LOBBY_STATE_SET_CURRENT_LOBBY;
}

export class SetAvailableLobbiesAction extends ActionWithPayload<
  LobbyActionsEnum,
  IVisibleLobby[]
> {
  public readonly type = LobbyActionsEnum.LOBBY_STATE_SET_AVAILABLE_LOBBIES;
}

export class SetLobbyAdminTokenAction extends ActionWithPayload<
  LobbyActionsEnum,
  string | undefined
> {
  public readonly type = LobbyActionsEnum.LOBBY_STATE_SET_LOBBY_ADMIN_TOKEN;
}

export class ClearAllLobbyStateAction extends Action<LobbyActionsEnum> {
  public readonly type = LobbyActionsEnum.LOBBY_STATE_CLEAR_ALL;
}

export class NewGameAvailableAction extends ActionWithPayload<
  LobbyActionsEnum,
  string
> {
  public readonly type = LobbyActionsEnum.LOBBY_STATE_NEW_GAME_AVAILABLE;
}

export class NewChatMessageAction extends ActionWithPayload<
  LobbyActionsEnum,
  IChatMessage
> {
  public readonly type = LobbyActionsEnum.LOBBY_STATE_NEW_CHAT_MESSAGE;
}

export type SetLobbyStateActions =
  | SetCurrentLobbyAction
  | SetAvailableLobbiesAction
  | SetLobbyAdminTokenAction
  | NewGameAvailableAction
  | ClearAllLobbyStateAction
  | NewChatMessageAction;

// We just make Dispatch available in our error callback
const easyError = (dispatch: DispatchWithMiddleWare) => (e: Error) => {
  dispatch(appActions.addError(e));
};

function createAndJoinLobby(lobbyName: string, userName: string): IThunk {
  return (dispatch, _, { lobbyService }) => {
    dispatch({ type: LobbyActionsEnum.LOBBY_STATE_CREATE_LOBBY });

    const lobbyArgs: ICreateLobbyArgs = {
      name: lobbyName,
      additionalGameProperties: { numberOfBots: 0 },
    };
    return lobbyService
      .createLobby(lobbyArgs)
      .then((createResult) => {
        dispatch(lobbyActions.setAdminToken(createResult.adminToken));
        return dispatch(joinLobby(createResult.lobbyId, userName, false));
      })
      .catch(easyError(dispatch));
  };
}

function joinLobby(
  lobbyId: string,
  userName: string,
  clearAdminToken = true
): IThunk {
  return (dispatch, getState, { lobbyService }) => {
    dispatch({ type: LobbyActionsEnum.LOBBY_STATE_JOIN });
    if (clearAdminToken) dispatch(lobbyActions.setAdminToken(undefined));
    return lobbyService
      .joinLobby(lobbyId, userName)
      .then((currentLobby) => {
        dispatch(lobbyActions.setCurrentLobby(currentLobby));
      })
      .catch((x: Error) => {
        if (!x.message.includes('not exist')) {
          easyError(dispatch);
        }
      });
  };
}

function updateLobby(args: IUpdateLobbyArgs): IThunk {
  return (dispatch, getState, { lobbyService }) => {
    dispatch({ type: LobbyActionsEnum.LOBBY_STATE_UPDATE_LOBBY });
    return lobbyService.updateLobby(args).catch(easyError(dispatch));
  };
}

function sendMessage(message: string): IThunk {
  return (dispatch, getState, { lobbyService }) => {
    const lobbyId = getState().currentLobbyId;
    if (!lobbyId) {
      return Promise.resolve();
    }
    return lobbyService
      .sendMessage(lobbyId, message)
      .catch(easyError(dispatch));
  };
}

function leaveLobby(lobbyId: string): IThunk {
  return (dispatch, getState, { lobbyService }) => {
    dispatch({ type: LobbyActionsEnum.LOBBY_STATE_LEAVE });

    dispatch(lobbyActions.clearAllLobbyState());
    return lobbyService.leaveLobby(lobbyId).catch(easyError(dispatch));
  };
}

function leaveLobbyIfInLobby(): IThunk {
  return (dispatch, getState, _) => {
    const state = getState();
    if (!state.currentLobbyId) {
      return Promise.resolve();
    }
    return dispatch(leaveLobby(state.currentLobbyId));
  };
}

function startNewGame(
  lobbyId: string,
  adminToken: string,
  config: IGameConfig
): IThunk {
  return async (dispatch, _, { lobbyService }) => {
    await dispatch({ type: LobbyActionsEnum.LOBBY_STATE_START_NEW_GAME });

    await dispatch(
      gameActions.createGame(config, (gameId) => {
        // Broadcast new game *should* be a feedback loop which will set the current game
        return lobbyService.broadcastNewGame(lobbyId, adminToken, gameId);
      })
    );
  };
}

function refreshLobbies(): IThunk {
  return async (dispatch, getStore, { lobbyService }) => {
    await lobbyService
      .listLobbies()
      .then((lobbies) => dispatch(new SetAvailableLobbiesAction(lobbies)));
  };
}

function clearCurrentGame(): IThunk {
  return async (dispatch, getStore, __) => {
    const state = getStore();
    if (state.lobbyAdminToken && state.currentLobby && state.currentLobbyId) {
      await dispatch(
        lobbyActions.updateLobby({
          adminToken: state.lobbyAdminToken,
          id: state.currentLobbyId,
          name: state.currentLobby.name,
          additionalGameProperties: {
            ...state.currentLobby.additionalGameProperties,
            currentGameId: undefined,
          },
        })
      );
    }
  };
}

function newGameAvailable(gameId: string): IThunk {
  return async (dispatch, getStore) => {
    const state = getStore();
    dispatch(new NewGameAvailableAction(gameId));
    if (navigator.vibrate) {
      navigator.vibrate(100);
    }
    await dispatch(gameActions.joinGame(gameId, state.name));
    if (state.lobbyAdminToken && state.currentLobby) {
      await dispatch(
        lobbyActions.updateLobby({
          ...state.currentLobby,
          adminToken: state.lobbyAdminToken,
          additionalGameProperties: {
            ...state.currentLobby.additionalGameProperties,
            currentGameId: gameId,
          },
        })
      );
    }
  };
}

export const lobbyActions = {
  createAndJoinLobby: createAndJoinLobby,
  leaveLobbyIfInLobby: leaveLobbyIfInLobby,
  refreshLobbies: refreshLobbies,
  setAvailableLobbies: (lobbies: IVisibleLobby[]) =>
    new SetAvailableLobbiesAction(lobbies),
  joinLobby: joinLobby,
  setAdminToken: (token: string | undefined) =>
    new SetLobbyAdminTokenAction(token),
  setCurrentLobby: (lobby: IVisibleLobby) => new SetCurrentLobbyAction(lobby),
  newGameAvailable,
  startNewGame,
  leaveLobby: leaveLobby,
  clearAllLobbyState: () => new ClearAllLobbyStateAction(),
  updateLobby: updateLobby,
  sendMessage: sendMessage,

  newMessage: (message: IChatMessage) => new NewChatMessageAction(message),
  clearCurrentGame,
};
