import { ICard, ISourcedCard } from './CardModels';

export interface IVisibleGameState {
  version: number;
  allPlayersConnected: boolean;
  deckCards: IUnrevealedCard[];
  lastPlayedCard?: ICard | IUnrevealedCard;
  playedCards: (ICard | IUnrevealedCard)[];
  burntCards: ICard[];
  player: IVisiblePlayer;
  opponents: IVisibleOpponent[];
  gameOver: boolean;
  isClientState?: boolean;
}

export interface IUnrevealedCard {
  cardId: string;
}

export interface IVisiblePlay {
  playerIndex: number;
  playResult: PlayResult;
  playedCards: ICard[];
}

export interface IPlay {
  cardsToPlay: ISourcedCard[];
}

export enum PlayResult {
  PICKED_UP = 0,
  BURNT = 1,
  NORMAL_PLAY = 2,
  FORFEIT = 3,
}

export interface IVisiblePlayer {
  idHash: string;
  name: string;
  visibleCards: ICard[];
  availablePlays: IPlay[];
  hiddenCards: IUnrevealedCard[];
  playerIndex: number;
  canSwap: boolean;
  canPickup: boolean;
  handCards: ICard[];
  isTurn: boolean;
  place: number | null;
  forfeited: boolean;
}

export interface IVisibleOpponent {
  idHash: string;
  connected: boolean;
  name: string;
  playerIndex: number;
  visibleCards: ICard[];
  hiddenCards: IUnrevealedCard[];
  handCards: IUnrevealedCard[];
  isTurn: boolean;
  place: number | null;
  forfeited: boolean;
}
