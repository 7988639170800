import { Component } from 'react';
import Modal from './Modal';
import './YesNoModal.css';

interface IYesNoModalProps {
  header: string;
  message: string;
  onYes?: () => void;
  onNo?: () => void;
}

export default class YesNoModal extends Component<IYesNoModalProps> {
  public render() {
    const { onYes, onNo } = this.props;
    const buttons = (
      <div className="yes-no-buttons">
        <button className="btn" onClick={onNo}>
          <span>No</span>
        </button>
        <button className="btn" onClick={onYes}>
          <span>Yes</span>
        </button>
      </div>
    );
    return <Modal {...this.props} buttons={buttons} />;
  }
}
