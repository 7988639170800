import { Component } from 'react';
import './Reconnect.css';
import { IConnectionState } from '../../../Reducers';

interface IReconnectProps {
  attemptReconnect: () => void;
  connectionState: IConnectionState;
}

interface IReconnectState {
  failedAutoConnect: boolean;
}

class Reconnect extends Component<IReconnectProps, IReconnectState> {
  state: IReconnectState = {
    failedAutoConnect: false,
  };
  timeout: NodeJS.Timeout | undefined;
  public render() {
    const { attemptReconnect } = this.props;
    if (this.state.failedAutoConnect) {
      return (
        <div className="reconnect">
          <div className="reconnect-box">
            <h1>Could Not Connect to the Game Service</h1>
            <button className="btn" onClick={attemptReconnect}>
              <span>Attempt Reconnect</span>
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="reconnect">
        <div className="reconnect-box">
          <h1>Connecting to the Game Service</h1>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  public componentDidMount() {
    if (this.props.connectionState.numRetries === 0) {
      this.props.attemptReconnect();
      this.timeout = setTimeout(() => {
        this.props.attemptReconnect();
        this.setState({ failedAutoConnect: true });
      }, this.props.connectionState.waitFactor);
    }
  }
}

export default Reconnect;
