import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ICard } from '../../../Models/CardModels';
import { IUnrevealedCard } from '../../../Models/StateModels';
import { IDragTarget } from '../../../Models/UIModels';
import BurntCards from '../BurntCards/BurntCards';
import Deck from '../Deck/Deck';
import Pile from '../Pile/Pile';
import './DeckAndPile.css';

interface IDeckAndPileProps {
  deckRemaining: IUnrevealedCard[];
  burntCards: IUnrevealedCard[];
  lastPlayedCard: ICard | IUnrevealedCard | undefined;
  playedCards: (ICard | IUnrevealedCard)[];
  setDragTarget(playAreaDragTarget: IDragTarget): void;
}

const DeckAndPile = ({
  deckRemaining,
  lastPlayedCard,
  playedCards,
  burntCards,
  setDragTarget,
}: IDeckAndPileProps) => {
  const [dragHovered, setDragHovered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setDragTarget({
      onDragEnter: () => setDragHovered(true),
      onDragExit: () => setDragHovered(false),
      getCoords: () =>
        ref?.current
          ? {
              width: ref.current.getBoundingClientRect().width,
              height: ref.current.getBoundingClientRect().height,
              y: ref.current.getBoundingClientRect().top,
              x: ref.current.getBoundingClientRect().left,
            }
          : undefined,
    });
  }, [setDragTarget]);
  return (
    <div className={'deck-and-pile'}>
      <div
        className={classNames('drag-target', { 'drag-hovered': dragHovered })}
        ref={ref}
      >
        <Deck deckRemaining={deckRemaining} />
        <Pile playedCards={playedCards} lastPlayedCard={lastPlayedCard} />
        <BurntCards burntCards={burntCards} />
      </div>
    </div>
  );
};

export default DeckAndPile;
