import { Action as IAction, AnyAction } from 'redux';
import { IStore } from './Reducers';
import { GameService } from './Services/GameService';
import { LobbyService } from './Services/LobbyService';
import { ClientGameService } from './Services/ClientGameService';
import { LeaderboardService } from './Services/LeaderboardService';
import { ThunkAction } from 'redux-thunk';

export class Action<Type> implements IAction {
  public readonly type: Type = null as Type;
}

export class ActionWithPayload<Type, Payload> extends Action<Type> {
  public readonly payload: Payload;
  public readonly meta?: unknown;
  public constructor(payload: Payload) {
    super();
    this.payload = payload;
  }
}
// What we're saying here is that if you pass dispatch a plain action it wont return anything
declare function DispatchWithMiddleWare(action: IAction): void;
// If you pass dispatch thunk, it will return whatever the thunk returns
declare function DispatchWithMiddleWare(action: IThunk): ReturnType<IThunk>;

export type DispatchWithMiddleWare = typeof DispatchWithMiddleWare;

export interface IServices {
  gameService: GameService;
  lobbyService: LobbyService;
  clientGameService: ClientGameService;
  leaderboardService: LeaderboardService;
}

// By enforcing our thunks to return a promise it means we
// can tell when our actions complete in our lower level components
// show loaders etc
// But we don't want to get into the habit of reading the data they return
// that should be set in the store. Hence void
// export type IThunk = (
//   dispatch: DispatchWithMiddleWare,
//   getState: () => IStore,
//   services: IServices
// ) => Promise<void>;

export type IThunk = ThunkAction<Promise<void>, IStore, IServices, AnyAction>;
