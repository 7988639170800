import classNames from 'classnames';
import { AnimatePresence, PanInfo } from 'framer-motion';
import { Component } from 'react';
import { isEmoji, isNotNull } from '../../../functions';
import { ICard, ISourcedCard } from '../../../Models/CardModels';
import { IChatMessage } from '../../../Models/LobbyModels';
import { IVisibleGameState, IVisiblePlay } from '../../../Models/StateModels';
import {
  IDragCoords,
  IDragTarget,
  ISourcedCardDragTarget,
} from '../../../Models/UIModels';
import { IDragTargetState } from '../../../Reducers';
import Opponents from '../../Opponent/Opponents';
import Player from '../../Player/Player';
import DeckAndPile from '../DeckAndPile/DeckAndPile';
import Ephemeral from '../Ephemeral/Ephemeral';
import GameOver from '../GameOver/GameOver';
import PlayHistory from '../PlayHistory/PlayHistory';
import './GameState.css';

interface IGameStateProps {
  visibleGameState: IVisibleGameState;
  playerId: number;
  playHistory: IVisiblePlay[];
  dragTargets: IDragTargetState;
  tryPlay: (play: ISourcedCard[]) => Promise<void>;
  leaveGame: () => Promise<void>;
  trySwap: (card1: ISourcedCard, card2: ISourcedCard) => Promise<void>;
  tryPickup: () => void;
  tryReorderHandCards: (cards: ICard[]) => Promise<void>;
  handleError: (e: Error) => void;

  sendMessage: (message: string) => void;
  setPlayAreaDragTarget(playAreaDragTarget: IDragTarget): void;
  setSourcedCardDragTarget(playAreaDragTarget: ISourcedCardDragTarget): void;

  messages: IChatMessage[];
}

interface IGameStateState {
  tab: 'game' | 'chat' | 'settings';
  emojiPickerActive: boolean;
}

class GameState extends Component<IGameStateProps, IGameStateState> {
  state: IGameStateState = {
    tab: 'game',
    emojiPickerActive: false,
  };
  public render() {
    const { leaveGame, handleError, setPlayAreaDragTarget } = this.props;
    const {
      opponents,
      lastPlayedCard,
      deckCards,
      playedCards,
      burntCards,
      player,
      gameOver,
    } = this.props.visibleGameState;
    const { playHistory } = this.props;
    const displayedOpponents = opponents.map((opponent) => ({
      opponent,
      lastSentMessage: this.props.messages
        .filter((x) => x.playerIdHash === opponent.idHash)
        .pop(),
    }));
    const lastPlayerMessage = this.props.messages
      .filter((x) => x.playerIdHash === player.idHash)
      .pop();

    const gameOverLayout = gameOver ? (
      <GameOver
        visibleGameState={this.props.visibleGameState}
        leaveGame={leaveGame}
      />
    ) : null;

    return (
      <AnimatePresence>
        <div className="game-state-wrapper">
          <div className="game-state">
            <Opponents
              playerIndex={player.playerIndex}
              opponents={displayedOpponents}
            />
            <section
              className={classNames('turn-indicator', {
                'your-turn': player.isTurn,
                'opponents-turn': !player.isTurn,
              })}
            >
              <div className="spacer"></div>
              {player.isTurn && <p className="turn">Your Turn</p>}
              {!player.isTurn && <p className="turn">Opponent's Turn</p>}
              <div className="emoji-btn-container">
                {isEmoji(lastPlayerMessage && lastPlayerMessage.message) ? (
                  <Ephemeral
                    id={lastPlayerMessage}
                    message={lastPlayerMessage && lastPlayerMessage.message}
                    classNames="player-last-message"
                  />
                ) : undefined}
                <button
                  className="emoji-btn emoji-btn-core"
                  onClick={this.toggleEmojiPicker}
                >
                  <span role="img" aria-label="emojis">
                    😄
                  </span>
                </button>
                {this.renderEmojiPicker()}
              </div>
            </section>
            <DeckAndPile
              deckRemaining={deckCards}
              lastPlayedCard={lastPlayedCard}
              playedCards={playedCards}
              burntCards={burntCards}
              setDragTarget={setPlayAreaDragTarget}
            />
            <PlayHistory
              playHistory={playHistory}
              player={player}
              opponents={opponents}
            />
            <Player
              player={player}
              dropPlayerCards={this.onPlayerCardsDragged}
              handleError={handleError}
              tryReorderHandCards={this.props.tryReorderHandCards}
              tryPickup={this.props.tryPickup}
              setDragTarget={this.props.setSourcedCardDragTarget}
              draggingPlayerCards={this.onPlayerCardsDragging}
            />
          </div>
        </div>
        {gameOverLayout}
      </AnimatePresence>
    );
  }

  private onPlayerCardsDragging = (cards: ISourcedCard[], info: PanInfo) => {
    this.exitAllDrags();
    const closestDragTargetInRange = getClosestDragTargetInRange(
      cards,
      this.props.dragTargets,
      info
    );

    // We only show hover states if the drag area is the play area
    if (
      !closestDragTargetInRange ||
      (cards.length > 1 && 'card' in closestDragTargetInRange)
    ) {
      return;
    }

    closestDragTargetInRange.onDragEnter();
  };

  private onPlayerCardsDragged = (cards: ISourcedCard[], info: PanInfo) => {
    this.exitAllDrags();
    const closestDragTargetInRange = getClosestDragTargetInRange(
      cards,
      this.props.dragTargets,
      info
    );
    if (!closestDragTargetInRange) {
      return;
    }
    if (
      closestDragTargetInRange === this.props.dragTargets.playAreaDragTarget
    ) {
      this.props.tryPlay(cards);
    }
    if ('card' in closestDragTargetInRange && cards.length === 1) {
      this.props.trySwap(cards[0], closestDragTargetInRange.card);
    }
  };

  exitAllDrags = () => {
    [this.props.dragTargets.playAreaDragTarget]
      .concat(this.props.dragTargets.sourcedCardDragTargets)
      .forEach((x) => x?.onDragExit());
  };

  renderEmojiPicker = () => {
    if (!this.state.emojiPickerActive) return undefined;
    return (
      <div onClick={this.toggleEmojiPicker} className="emoji-picker">
        <button className="emoji-btn-core" onClick={this.sendMessage('👍')}>
          <span role="img" aria-label="thumbs up">
            👍
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😄')}>
          <span role="img" aria-label="big smile">
            😄
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😍')}>
          <span role="img" aria-label="heart eyes">
            😍
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😮')}>
          <span role="img" aria-label="wow">
            😮
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('💩')}>
          <span role="img" aria-label="poo">
            💩
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😠')}>
          <span role="img" aria-label="angry">
            😠
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😢')}>
          <span role="img" aria-label="cry">
            😢
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('🥴')}>
          <span role="img" aria-label="woozy">
            🥴
          </span>
        </button>
      </div>
    );
  };

  toggleEmojiPicker = () => {
    this.setState({ emojiPickerActive: !this.state.emojiPickerActive });
  };

  messageFunctions: { [msg: string]: () => void } = {};
  sendMessage = (message: string) => {
    if (this.messageFunctions[message]) {
      return this.messageFunctions[message];
    }
    this.messageFunctions[message] = () => {
      this.props.sendMessage(message);
    };
    return this.messageFunctions[message];
  };
}

export default GameState;

const getClosestDragTargetInRange = (
  draggingCards: ISourcedCard[],
  targets: IDragTargetState,
  info: PanInfo
): IDragTarget | ISourcedCardDragTarget | undefined => {
  const playAreaCoords = targets.playAreaDragTarget?.getCoords();
  const distanceToPlayArea =
    playAreaCoords && isInDragArea(playAreaCoords, info);
  const playAreaInRange = !!distanceToPlayArea;

  const sourcedCards = targets.sourcedCardDragTargets
    .filter(
      (c) => !draggingCards.some((x) => x.card.cardId === c.card.card.cardId)
    )
    .map((target) => {
      const coords = target.getCoords();
      return coords
        ? {
            target,
            isInDragArea: isInDragArea(coords, info),
            coords,
          }
        : undefined;
    })
    .filter(isNotNull);
  const sourcedCardsInRange = sourcedCards.filter(
    ({ isInDragArea }) => isInDragArea
  );

  const closestSourceCardInRange = sourcedCardsInRange.at(0);
  if (
    distanceToPlayArea !== undefined &&
    closestSourceCardInRange !== undefined
  ) {
    if (playAreaInRange) {
      return targets.playAreaDragTarget;
    }
    return closestSourceCardInRange.target;
  }
  if (playAreaInRange) {
    return targets.playAreaDragTarget;
  }
  return closestSourceCardInRange?.target;
};

const isInDragArea = (target: IDragCoords, info: PanInfo) => {
  const x1 = info.point.x;
  const y1 = info.point.y;
  const x2 = target.x;
  const y2 = target.y;
  const x3 = target.x + target.width;
  const y3 = target.y + target.height;
  return x1 <= x3 && x1 >= x2 && y1 <= y3 && y1 >= y2;
};
