/*-----------------------------------
Gen via:
result = "";
for(let i=2; i <=14; i++){
for(let j = 0; j<4; j++){
    let suit = "";
    let suitCap = "";
    if (j == 0) {
      suit = "clubs";
      suitCap = "Clubs";
    }
    if (j == 1) {
      suit = "diamonds";
      suitCap = "Diamonds";
    }
    if (j == 2) {
      suit = "hearts";
      suitCap = "Hearts";
    }
    if (j == 3) {
      suit = "spades";
      suitCap = "Spades";
    }
let val = i;
if(val == 11){
val = "jack"
}
if(i == 12){
val = "queen"
}
if(i=="13"){
val = "king"
}
if(i == 14){
val = "ace"
}
result +="import {ReactComponent as "+suitCap+""+val+"} from '../Card/asset/"+suit+"/"+val+"_of_"+suit+".svg';"
	result+="<br/>";
}
}
document.getElementById("res").innerHTML=result
*/
import { ReactComponent as Clubs2 } from '../Card/asset/clubs/2_of_clubs.svg';
import { ReactComponent as Diamonds2 } from '../Card/asset/diamonds/2_of_diamonds.svg';
import { ReactComponent as Hearts2 } from '../Card/asset/hearts/2_of_hearts.svg';
import { ReactComponent as Spades2 } from '../Card/asset/spades/2_of_spades.svg';
import { ReactComponent as Clubs3 } from '../Card/asset/clubs/3_of_clubs.svg';
import { ReactComponent as Diamonds3 } from '../Card/asset/diamonds/3_of_diamonds.svg';
import { ReactComponent as Hearts3 } from '../Card/asset/hearts/3_of_hearts.svg';
import { ReactComponent as Spades3 } from '../Card/asset/spades/3_of_spades.svg';
import { ReactComponent as Clubs4 } from '../Card/asset/clubs/4_of_clubs.svg';
import { ReactComponent as Diamonds4 } from '../Card/asset/diamonds/4_of_diamonds.svg';
import { ReactComponent as Hearts4 } from '../Card/asset/hearts/4_of_hearts.svg';
import { ReactComponent as Spades4 } from '../Card/asset/spades/4_of_spades.svg';
import { ReactComponent as Clubs5 } from '../Card/asset/clubs/5_of_clubs.svg';
import { ReactComponent as Diamonds5 } from '../Card/asset/diamonds/5_of_diamonds.svg';
import { ReactComponent as Hearts5 } from '../Card/asset/hearts/5_of_hearts.svg';
import { ReactComponent as Spades5 } from '../Card/asset/spades/5_of_spades.svg';
import { ReactComponent as Clubs6 } from '../Card/asset/clubs/6_of_clubs.svg';
import { ReactComponent as Diamonds6 } from '../Card/asset/diamonds/6_of_diamonds.svg';
import { ReactComponent as Hearts6 } from '../Card/asset/hearts/6_of_hearts.svg';
import { ReactComponent as Spades6 } from '../Card/asset/spades/6_of_spades.svg';
import { ReactComponent as Clubs7 } from '../Card/asset/clubs/7_of_clubs.svg';
import { ReactComponent as Diamonds7 } from '../Card/asset/diamonds/7_of_diamonds.svg';
import { ReactComponent as Hearts7 } from '../Card/asset/hearts/7_of_hearts.svg';
import { ReactComponent as Spades7 } from '../Card/asset/spades/7_of_spades.svg';
import { ReactComponent as Clubs8 } from '../Card/asset/clubs/8_of_clubs.svg';
import { ReactComponent as Diamonds8 } from '../Card/asset/diamonds/8_of_diamonds.svg';
import { ReactComponent as Hearts8 } from '../Card/asset/hearts/8_of_hearts.svg';
import { ReactComponent as Spades8 } from '../Card/asset/spades/8_of_spades.svg';
import { ReactComponent as Clubs9 } from '../Card/asset/clubs/9_of_clubs.svg';
import { ReactComponent as Diamonds9 } from '../Card/asset/diamonds/9_of_diamonds.svg';
import { ReactComponent as Hearts9 } from '../Card/asset/hearts/9_of_hearts.svg';
import { ReactComponent as Spades9 } from '../Card/asset/spades/9_of_spades.svg';
import { ReactComponent as Clubs10 } from '../Card/asset/clubs/10_of_clubs.svg';
import { ReactComponent as Diamonds10 } from '../Card/asset/diamonds/10_of_diamonds.svg';
import { ReactComponent as Hearts10 } from '../Card/asset/hearts/10_of_hearts.svg';
import { ReactComponent as Spades10 } from '../Card/asset/spades/10_of_spades.svg';
import { ReactComponent as Clubsjack } from '../Card/asset/clubs/jack_of_clubs.svg';
import { ReactComponent as Diamondsjack } from '../Card/asset/diamonds/jack_of_diamonds.svg';
import { ReactComponent as Heartsjack } from '../Card/asset/hearts/jack_of_hearts.svg';
import { ReactComponent as BackOfCard } from '../Card/asset/back_of_card/back_of_cards.svg';
import { ReactComponent as Spadesjack } from '../Card/asset/spades/jack_of_spades.svg';
import { ReactComponent as Clubsqueen } from '../Card/asset/clubs/queen_of_clubs.svg';
import { ReactComponent as Diamondsqueen } from '../Card/asset/diamonds/queen_of_diamonds.svg';
import { ReactComponent as Heartsqueen } from '../Card/asset/hearts/queen_of_hearts.svg';
import { ReactComponent as Spadesqueen } from '../Card/asset/spades/queen_of_spades.svg';
import { ReactComponent as Clubsking } from '../Card/asset/clubs/king_of_clubs.svg';
import { ReactComponent as Diamondsking } from '../Card/asset/diamonds/king_of_diamonds.svg';
import { ReactComponent as Heartsking } from '../Card/asset/hearts/king_of_hearts.svg';
import { ReactComponent as Spadesking } from '../Card/asset/spades/king_of_spades.svg';
import { ReactComponent as Clubsace } from '../Card/asset/clubs/ace_of_clubs.svg';
import { ReactComponent as Diamondsace } from '../Card/asset/diamonds/ace_of_diamonds.svg';
import { ReactComponent as Heartsace } from '../Card/asset/hearts/ace_of_hearts.svg';
import { ReactComponent as Spadesace } from '../Card/asset/spades/ace_of_spades.svg';
import { CardValue, CardSuit, ICard } from '../../../Models/CardModels';
import { IUnrevealedCard } from '../../../Models/StateModels';

/* --------------------------------------------------------------------------
result = "";
for (let i = 2; i <= 14; i++) {
  for (let j = 0; j < 4; j++) {
    let suit = "";
    let suitCap = "";
    if (j == 0) {
      suit = "clubs";
      suitCap = "Clubs";
    }
    if (j == 1) {
      suit = "diamonds";
      suitCap = "Diamonds";
    }
    if (j == 2) {
      suit = "hearts";
      suitCap = "Hearts";
    }
    if (j == 3) {
      suit = "spades";
      suitCap = "Spades";
    }
    let val = i;
    let cardVal = "";
    if (i == 2) {
      cardVal = "TWO";
    }
    if (i == 3) {
      cardVal = "THREE";
    }
    if (i == 4) {
      cardVal = "FOUR";
    }
    if (i == 5) {
      cardVal = "FIVE";
    }
    if (i == 6) {
      cardVal = "SIX";
    }
    if (i == 7) {
      cardVal = "SEVEN";
    }
    if (i == 8) {
      cardVal = "EIGHT";
    }
    if (i == 9) {
      cardVal = "NINE";
    }
    if (i == 10) {
      cardVal = "TEN";
    }
    if (val == 11) {
      val = "jack"
      cardVal = "JACK";
    }
    if (i == 12) {
      val = "queen"
      cardVal = "QUEEN";
    }
    if (i == "13") {
      val = "king"
      cardVal = "KING";
    }
    if (i == 14) {
      val = "ace"
      cardVal = "ACE";
    }
    result += "if(card.value===CardValue." + cardVal + " && card.suit === CardSuit."+suit.toUpperCase()+") { return &lt;"+suitCap + "_" + val+"/&gt;;}"
    result += "<br/>";
  }
}
document.getElementById("res").innerHTML = result
*/
export const loadCard = (
  card: ICard | IUnrevealedCard,
  extraClass?: string
): JSX.Element => {
  if (!('value' in card)) {
    return <BackOfCard className={'card card-hidden ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.TWO && card.suit === CardSuit.CLUBS) {
    return <Clubs2 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.TWO && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds2 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.TWO && card.suit === CardSuit.HEARTS) {
    return <Hearts2 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.TWO && card.suit === CardSuit.SPADES) {
    return <Spades2 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.THREE && card.suit === CardSuit.CLUBS) {
    return <Clubs3 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.THREE && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds3 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.THREE && card.suit === CardSuit.HEARTS) {
    return <Hearts3 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.THREE && card.suit === CardSuit.SPADES) {
    return <Spades3 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.FOUR && card.suit === CardSuit.CLUBS) {
    return <Clubs4 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.FOUR && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds4 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.FOUR && card.suit === CardSuit.HEARTS) {
    return <Hearts4 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.FOUR && card.suit === CardSuit.SPADES) {
    return <Spades4 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.FIVE && card.suit === CardSuit.CLUBS) {
    return <Clubs5 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.FIVE && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds5 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.FIVE && card.suit === CardSuit.HEARTS) {
    return <Hearts5 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.FIVE && card.suit === CardSuit.SPADES) {
    return <Spades5 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.SIX && card.suit === CardSuit.CLUBS) {
    return <Clubs6 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.SIX && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds6 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.SIX && card.suit === CardSuit.HEARTS) {
    return <Hearts6 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.SIX && card.suit === CardSuit.SPADES) {
    return <Spades6 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.SEVEN && card.suit === CardSuit.CLUBS) {
    return <Clubs7 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.SEVEN && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds7 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.SEVEN && card.suit === CardSuit.HEARTS) {
    return <Hearts7 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.SEVEN && card.suit === CardSuit.SPADES) {
    return <Spades7 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.EIGHT && card.suit === CardSuit.CLUBS) {
    return <Clubs8 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.EIGHT && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds8 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.EIGHT && card.suit === CardSuit.HEARTS) {
    return <Hearts8 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.EIGHT && card.suit === CardSuit.SPADES) {
    return <Spades8 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.NINE && card.suit === CardSuit.CLUBS) {
    return <Clubs9 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.NINE && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds9 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.NINE && card.suit === CardSuit.HEARTS) {
    return <Hearts9 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.NINE && card.suit === CardSuit.SPADES) {
    return <Spades9 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.TEN && card.suit === CardSuit.CLUBS) {
    return <Clubs10 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.TEN && card.suit === CardSuit.DIAMONDS) {
    return <Diamonds10 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.TEN && card.suit === CardSuit.HEARTS) {
    return <Hearts10 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.TEN && card.suit === CardSuit.SPADES) {
    return <Spades10 className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.JACK && card.suit === CardSuit.CLUBS) {
    return <Clubsjack className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.JACK && card.suit === CardSuit.DIAMONDS) {
    return <Diamondsjack className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.JACK && card.suit === CardSuit.HEARTS) {
    return <Heartsjack className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.JACK && card.suit === CardSuit.SPADES) {
    return <Spadesjack className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.QUEEN && card.suit === CardSuit.CLUBS) {
    return <Clubsqueen className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.QUEEN && card.suit === CardSuit.DIAMONDS) {
    return <Diamondsqueen className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.QUEEN && card.suit === CardSuit.HEARTS) {
    return <Heartsqueen className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.QUEEN && card.suit === CardSuit.SPADES) {
    return <Spadesqueen className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.KING && card.suit === CardSuit.CLUBS) {
    return <Clubsking className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.KING && card.suit === CardSuit.DIAMONDS) {
    return <Diamondsking className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.KING && card.suit === CardSuit.HEARTS) {
    return <Heartsking className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.KING && card.suit === CardSuit.SPADES) {
    return <Spadesking className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.ACE && card.suit === CardSuit.CLUBS) {
    return <Clubsace className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.ACE && card.suit === CardSuit.DIAMONDS) {
    return <Diamondsace className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.ACE && card.suit === CardSuit.HEARTS) {
    return <Heartsace className={'card ' + (extraClass || '')} />;
  }
  if (card.value === CardValue.ACE && card.suit === CardSuit.SPADES) {
    return <Spadesace className={'card ' + (extraClass || '')} />;
  }
  return <BackOfCard className={'card ' + (extraClass || '')} />;
};
