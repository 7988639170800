import { Component } from 'react';
import { IVisibleLobby } from '../../../Models/LobbyModels';
import ActionBar from '../ActionBar/ActionBar';
import './LobbyList.css';
import LobbyListItem from '../LobbyListItem/LobbyListItem';

interface ILobbyListProps {
  playerName: string;
  lobbyList: IVisibleLobby[];
  createAndJoinLobby: (lobbyName: string) => void;
  goToLeaderboard: () => void;
  joinLobby: (lobbyId: string) => void;
  refreshLobbies: () => Promise<void>;
  onBackButtonClick: () => void;
}

class LobbyList extends Component<ILobbyListProps> {
  public componentDidMount() {
    this.props.refreshLobbies();
  }

  public render() {
    const { lobbyList, onBackButtonClick } = this.props;
    return (
      <>
        <ActionBar backButtonClicked={onBackButtonClick} title="Games" />
        <div className="lobby-list">
          <div className="lobbies">{this.renderLobbies(lobbyList)}</div>
          <div className="bottom-buttons">
            <button
              className="btn leaderboard-btn"
              onClick={this.goToLeaderboard}
            >
              <span>Leaderboard</span>
            </button>
            <button className="btn new-game-btn" onClick={this.createLobby}>
              <span>New Game</span>
            </button>
          </div>
        </div>
      </>
    );
  }

  private renderLobbies(lobbyList: IVisibleLobby[]) {
    if (lobbyList.length === 0) {
      return <p>No games found :(</p>;
    }
    return (
      <>
        <p>Click to join a lobby</p>
        {lobbyList.map(lobby => (
          <LobbyListItem
            key={lobby.id}
            lobby={lobby}
            joinLobby={this.props.joinLobby}
          />
        ))}
      </>
    );
  }

  private createLobby = () => {
    const { createAndJoinLobby, playerName } = this.props;
    createAndJoinLobby(`${playerName}'s Game`);
  };

  private goToLeaderboard = () => {
    const { goToLeaderboard } = this.props;
    goToLeaderboard();
  };
}

export default LobbyList;
