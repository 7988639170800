import { Component } from 'react';
import { ReactComponent as Back } from './back.svg';
import './ActionBar.css';

interface IActionBarProps {
  title: string;
  backButtonClicked: () => void;
}

export default class ActionBar extends Component<IActionBarProps> {
  public render() {
    const { backButtonClicked, title } = this.props;
    return (
      <div className="action-bar">
        <Back className="back" onClick={backButtonClicked} />
        <h2>{title}</h2>
      </div>
    );
  }
}
