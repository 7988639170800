import { Action, ActionWithPayload } from '../definitions';
import { ISourcedCard } from '../Models/CardModels';
import { IDragTarget, ISourcedCardDragTarget } from '../Models/UIModels';

export enum UiActionsEnum {
  UI_SET_PLAY_AREA_DRAG_TARGET = 'UI_SET_PLAY_AREA_DRAG_TARGET',
  UI_SET_SOURCED_CARD_DRAG_TARGET = 'UI_SET_SOURCED_CARD_DRAG_TARGET',
  UI_DELETE_SOURCED_CARD_DRAG_TARGET = 'UI_DELETE_SOURCED_CARD_DRAG_TARGET',
  UI_CLEAR_DRAG_TARGETS = 'UI_CLEAR_DRAG_TARGETS',
}

export class SetPlayAreaDragTargetAction extends ActionWithPayload<
  UiActionsEnum,
  IDragTarget
> {
  public readonly type = UiActionsEnum.UI_SET_PLAY_AREA_DRAG_TARGET;
}

export class SetSourcedCardDragTargetAction extends ActionWithPayload<
  UiActionsEnum,
  ISourcedCardDragTarget
> {
  public readonly type = UiActionsEnum.UI_SET_SOURCED_CARD_DRAG_TARGET;
}

export class DeleteSourcedCardDragTargetAction extends ActionWithPayload<
  UiActionsEnum,
  ISourcedCard
> {
  public readonly type = UiActionsEnum.UI_DELETE_SOURCED_CARD_DRAG_TARGET;
}

export class ClearDragTargetsAction extends Action<UiActionsEnum> {
  public readonly type = UiActionsEnum.UI_CLEAR_DRAG_TARGETS;
}

export type SetUiStateActions =
  | SetPlayAreaDragTargetAction
  | SetSourcedCardDragTargetAction
  | DeleteSourcedCardDragTargetAction
  | ClearDragTargetsAction;
