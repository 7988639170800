import { HubConnection } from '@microsoft/signalr';
import { ICard, ISourcedCard } from '../Models/CardModels';
import { IGameConfig, IJoinGameResult } from '../Models/GameSetupModels';
import { IVisibleGameState, IVisiblePlay } from '../Models/StateModels';

export class GameService {
  private connected = false;
  private connecting: Promise<void> | undefined;
  private connectionCallbacks: (() => void)[] = [];
  public constructor(private connection: HubConnection) {}

  public async attemptPlay(gameId: string, play: ISourcedCard[]) {
    await this.connect();
    return await this.connection.invoke('AttemptPlay', gameId, play);
  }

  public async attemptSwap(
    gameId: string,
    card1: ISourcedCard,
    card2: ISourcedCard
  ) {
    await this.connect();
    return await this.connection.invoke('AttemptSwap', gameId, card1, card2);
  }

  public async attemptPickup(gameId: string) {
    await this.connect();
    return await this.connection.invoke('AttemptPickup', gameId);
  }

  public async attempReorderHandCards(gameId: string, cards: ICard[]) {
    return await this.connection.invoke(
      'AttemptReorderHandCards',
      gameId,
      cards
    );
  }

  public async getCurrentState(gameId: string): Promise<IVisibleGameState> {
    await this.connect();
    return await this.connection.invoke('GetGameState', gameId);
  }

  public subscribe(
    gsSubscriber: (visibleGameState: IVisibleGameState) => void,
    playSubscriber: (visiblePlay: IVisiblePlay) => void,
    onConnected: () => void,
    onDisconnected: () => void
  ) {
    this.connection.on('newGameStateAvailable', async (gameId) =>
      gsSubscriber(await this.connection.invoke('getGameState', gameId))
    );
    this.connection.on('receiveVisiblePlay', playSubscriber);

    this.connection.onclose(() => {
      onDisconnected();
      this.connected = false;
    });
    this.connectionCallbacks.push(onConnected);
  }

  public async rejoinGame(
    gameId: string,
    reconnectToken: string
  ): Promise<void> {
    await this.connect();
    return await this.connection.invoke(
      'rejoinGame',
      gameId,
      reconnectToken,
      false
    );
  }

  public async replaceBotInGame(
    gameId: string,
    name: string
  ): Promise<IJoinGameResult> {
    await this.connect();
    return await this.connection.invoke('replaceBot', gameId, name);
  }

  public async connect() {
    if (this.connected) return;
    if (!this.connecting) {
      this.connecting = this.connection.start();
      this.connecting
        .then(() => this.connectionCallbacks.forEach((f) => f()))
        .finally(() => (this.connecting = undefined));
    }
    await this.connecting;
    this.connected = true;
  }

  // Sending of messages
  public async create(config: IGameConfig) {
    await this.connect();
    return await this.connection.invoke(
      'CreateNewGame',
      config.numberOfPlayers,
      config.gameRules,
      config.numberOfBots
    );
  }

  public async joinGame(
    gameId: string,
    name: string
  ): Promise<IJoinGameResult> {
    await this.connect();
    return await this.connection.invoke('JoinGame', gameId, name, false);
  }

  public async leaveGame(gameId: string) {
    await this.connect();
    return await this.connection.invoke('LeaveGame', gameId);
  }
}
