import { Component, createRef } from 'react';
import { IVisibleLobby, IChatMessage } from '../../../Models/LobbyModels';
import './Chat.css';
interface IChatProps {
  lobby: IVisibleLobby;
  messages: IChatMessage[];

  sendMessage: (message: string) => void;
}

interface IChatState {
  messageInput: string;
  autoScrollToBottom: boolean;
  unseenMessages: boolean;
}

interface IDisplayableChatMessage extends IChatMessage {
  playerName: string;
}

class Chat extends Component<IChatProps, IChatState> {
  state: IChatState = {
    messageInput: '',
    autoScrollToBottom: true,
    unseenMessages: false,
  };

  messagesEndRef = createRef<HTMLDivElement>();

  public render() {
    return (
      <article className="chat">
        <section className="messages" onScroll={this.onMessageScroll}>
          {this.renderMessages()}
          <div ref={this.messagesEndRef}></div>
          <span
            className="unseen-messages"
            hidden={!this.state.unseenMessages}
            onClick={this.scrollMessagesToBottom}
          >
            New messages available
          </span>
        </section>
        <form className="send" onSubmit={this.onFormSubmit}>
          <input
            type="text"
            onChange={this.onMessageChange}
            value={this.state.messageInput}
          />
          <button className="send-button btn accent">Send</button>
        </form>
      </article>
    );
  }

  getSnapshotBeforeUpdate(prevProps: IChatProps) {
    if (this.props.messages.length > prevProps.messages.length) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.scrollMessagesToBottom();
  }

  componentDidUpdate(_: IChatProps, _a: IChatState, newMessage: boolean) {
    if (newMessage) {
      if (this.state.autoScrollToBottom) {
        this.scrollMessagesToBottom();
      } else {
        this.setState({ unseenMessages: true });
      }
    }
  }

  private scrollMessagesToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView();
    }
  };

  private renderMessages() {
    const { messages } = this.props;

    const displayableMessages = messages
      .map(this.toDisplayableMessage)
      .filter(Boolean)
      .map((x) => x as IDisplayableChatMessage);

    return displayableMessages.map((message) => (
      <section
        key={message.playerIdHash + message.sentAt + message.sentAt}
        className="message"
      >
        <span className="author">{message.playerName}</span>
        <span className="message-contents">{message.message}</span>
      </section>
    ));
  }

  private toDisplayableMessage = (message: IChatMessage) => {
    const player = this.props.lobby.players.find(
      (player) => player.idHash === message.playerIdHash
    );
    if (player) {
      return { ...message, playerName: player.name };
    }

    return undefined;
  };

  private onFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (this.state.messageInput) {
      this.props.sendMessage(this.state.messageInput);
      this.setState({ messageInput: '' });
      this.scrollMessagesToBottom();
    }
  };

  private onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ messageInput: event.target.value });
  };

  private onMessageScroll = (event: React.UIEvent<HTMLElement>) => {
    const isScrolledBottom =
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight >=
      event.currentTarget.scrollTop - 10;

    if (isScrolledBottom) {
      this.setState({ unseenMessages: false });
    }
    this.setState({ autoScrollToBottom: isScrolledBottom });
  };
}

export default Chat;
