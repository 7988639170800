import { Component } from 'react';
import classNames from 'classnames';
import './Ephemeral.css';
import { isEmoji } from '../../../functions';

export interface IEphemeralProps {
  message: string | undefined;
  id: unknown | undefined;
  classNames?: string;
}

export interface IEphemeralState {
  messageFading: boolean;
}
export default class Ephemeral extends Component<
  IEphemeralProps,
  IEphemeralState
> {
  state: IEphemeralState = { messageFading: true };
  timeout: NodeJS.Timeout | undefined;
  getSnapshotBeforeUpdate(
    prevProps: IEphemeralProps,
    prevState: IEphemeralState
  ) {
    if (this.props.id !== prevProps.id) {
      return true;
    }
    return false;
  }

  componentDidUpdate(
    _: IEphemeralProps,
    _a: IEphemeralState,
    shouldFadeMessage: boolean
  ) {
    if (shouldFadeMessage) {
      this.setState({ messageFading: false });
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      if (!this.state.messageFading) this.setState({ messageFading: true });
    }, 1000);
  }

  render() {
    const { message } = this.props;
    const { messageFading } = this.state;
    return (
      <span
        className={classNames(
          {
            fade: messageFading,
            'emoji-no-border': isEmoji(message),
          },
          'ephemeral',
          this.props.classNames
        )}
      >
        <button>{message == null ? undefined : message}</button>
      </span>
    );
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
