import { Screen } from '../Components/General/StartGame/StartGame';
import { ActionWithPayload, IThunk } from '../definitions';
import { IGameConfig } from '../Models/GameSetupModels';
import { gameActions } from './GameActions';
import { IConnectionState } from '../Reducers';
import { lobbyActions } from './LobbyActions';
import { ILeaderboard } from '../Models/LeaderboardModels';
export enum AppActionsEnum {
  APP_STATE_ADD_ERROR = 'APP_STATE_ADD_ERROR',
  APP_STATE_SET_BEFORE_GAME_SCREEN = 'APP_STATE_SET_BEFORE_GAME_SCREEN',
  APP_STATE_REMOVE_ERROR = 'APP_STATE_REMOVE_ERROR',
  APP_STATE_SET_GAME_CONNECTION_STATE = 'APP_STATE_SET_GAME_CONNECTION_STATE',
  APP_STATE_SET_LOBBY_CONNECTION_STATE = 'APP_STATE_SET_LOBBY_CONNECTION_STATE',
  APP_STATE_ATTEMPT_LOBBY_RECONNECT = 'APP_STATE_ATTEMPT_LOBBY_RECONNECT',
  APP_STATE_ATTEMPT_GAME_RECONNECT = 'APP_STATE_ATTEMPT_GAME_RECONNECT',
  APP_STATE_SET_NAME = 'APP_STATE_SET_NAME',
  APP_STATE_SET_GAMECONFIG = 'APP_STATE_SET_GAMECONFIG',
  APP_STATE_REFRESH_LEADERBOARD = 'APP_STATE_REFRESH_LEADERBOARD',
}
export class AddErrorAction extends ActionWithPayload<AppActionsEnum, unknown> {
  public readonly type = AppActionsEnum.APP_STATE_ADD_ERROR;
}

export class RemoveErrorAction extends ActionWithPayload<
  AppActionsEnum,
  string
> {
  public readonly type = AppActionsEnum.APP_STATE_REMOVE_ERROR;
}

export class SetGameConnectedAction extends ActionWithPayload<
  AppActionsEnum,
  IConnectionState
> {
  public readonly type = AppActionsEnum.APP_STATE_SET_GAME_CONNECTION_STATE;
}

export class SetLobbyConnectedAction extends ActionWithPayload<
  AppActionsEnum,
  IConnectionState
> {
  public readonly type = AppActionsEnum.APP_STATE_SET_LOBBY_CONNECTION_STATE;
}

export class SetBeforeGameScreenAction extends ActionWithPayload<
  AppActionsEnum,
  Screen
> {
  public readonly type = AppActionsEnum.APP_STATE_SET_BEFORE_GAME_SCREEN;
}
export class SetNameAction extends ActionWithPayload<AppActionsEnum, string> {
  public readonly type = AppActionsEnum.APP_STATE_SET_NAME;
}

export class SetGameConfigAction extends ActionWithPayload<
  AppActionsEnum,
  IGameConfig
> {
  public readonly type = AppActionsEnum.APP_STATE_SET_GAMECONFIG;
}
export class RefreshLeaderboardAction extends ActionWithPayload<
  AppActionsEnum,
  ILeaderboard
> {
  public readonly type = AppActionsEnum.APP_STATE_REFRESH_LEADERBOARD;
}

export type SetAppStateActions =
  | AddErrorAction
  | RemoveErrorAction
  | SetGameConnectedAction
  | SetLobbyConnectedAction
  | SetBeforeGameScreenAction
  | SetNameAction
  | SetGameConfigAction
  | RefreshLeaderboardAction;

export const appActions = {
  addError: (e: unknown) => new AddErrorAction(e),
  removeError: (index: string) => new RemoveErrorAction(index),
  setGameConnectionState: (state: IConnectionState) =>
    new SetGameConnectedAction(state),
  setLobbyConnectionState: (state: IConnectionState) =>
    new SetLobbyConnectedAction(state),
  setName: (name: string) => new SetNameAction(name),
  setGameConfig: (config: IGameConfig) => new SetGameConfigAction(config),
  attemptGameReconnect,
  attemptLobbyReconnect,
  setBeforeGameScreen: (screen: Screen) =>
    new SetBeforeGameScreenAction(screen),
  refreshLeaderboard,
};

function attemptGameReconnect(): IThunk {
  return async (dispatch, getState, { gameService }) => {
    const store = getState();
    dispatch({ type: AppActionsEnum.APP_STATE_ATTEMPT_GAME_RECONNECT });
    await gameService.connect();
    if (store.gameId && store.reconnectToken) {
      await dispatch(
        gameActions.rejoinGame(store.gameId, store.reconnectToken)
      );
    } else {
      store.gameId = undefined;
      store.reconnectToken = undefined;
      store.visibleGameState = undefined;
    }
  };
}
function attemptLobbyReconnect(): IThunk {
  return async (dispatch, getState, { lobbyService }) => {
    const store = getState();
    dispatch({ type: AppActionsEnum.APP_STATE_ATTEMPT_LOBBY_RECONNECT });
    await lobbyService.connect();
    if (store.currentLobbyId) {
      await dispatch(
        lobbyActions.joinLobby(store.currentLobbyId, store.name, false)
      );
    }
  };
}

function refreshLeaderboard(): IThunk {
  return async (dispatch, _, { leaderboardService }) => {
    try {
      const lb = await leaderboardService.getLeaderboard();
      dispatch(new RefreshLeaderboardAction(lb));
    } catch (e) {
      console.error(e);
      dispatch(appActions.addError(new Error('Could not refresh leaderboard')));
    }
  };
}
