import {
  Action,
  ActionWithPayload,
  DispatchWithMiddleWare,
  IThunk,
} from '../definitions';
import { ICard, ISourcedCard } from '../Models/CardModels';
import { IGameConfig } from '../Models/GameSetupModels';
import { IVisibleGameState, IVisiblePlay } from '../Models/StateModels';
import { appActions } from './AppActions';

export enum GameActionsEnum {
  GAME_STATE_SET_VISIBLE_STATE = 'GAME_STATE_SET_VISIBLE_STATE',
  GAME_STATE_ADD_VISIBLE_PLAY = 'GAME_STATE_ADD_VISIBLE_PLAY',
  GAME_STATE_SET_PLAYER_ID = 'GAME_STATE_SET_PLAYER_ID',
  GAME_STATE_SET_GAME_ID = 'GAME_STATE_SET_GAME_ID',
  GAME_STATE_SET_RECONNECT_TOKEN = 'GAME_STATE_SET_RECONNECT_TOKEN',
  GAME_STATE_CREATE = 'GAME_STATE_CREATE',
  GAME_STATE_CLEAR_ALL = 'GAME_STATE_CLEAR_ALL',
  GAME_STATE_JOIN = 'GAME_STATE_JOIN',
  GAME_STATE_LEAVE = 'GAME_STATE_LEAVE',
  GAME_STATE_REJOIN = 'GAME_STATE_REJOIN',
  GAME_STATE_REPLACE_BOT = 'GAME_STATE_REPLACE_BOT',
  GAME_STATE_ATTEMPT_PLAY = 'GAME_STATE_ATTEMPT_PLAY',
  GAME_STATE_ATTEMPT_SWAP = 'GAME_STATE_ATTEMPT_SWAP',
  GAME_STATE_ATTEMPT_REORDER_HAND_CARDS = 'GAME_STATE_ATTEMPT_REORDER_HAND_CARDS',
  GAME_STATE_ATTEMPT_PICKUP = 'GAME_STATE_ATTEMPT_PICKUP',
}
export class SetPlayerIdAction extends ActionWithPayload<
  GameActionsEnum,
  number | undefined
> {
  public readonly type = GameActionsEnum.GAME_STATE_SET_PLAYER_ID;
}

export class SetGamedIdActionAction extends ActionWithPayload<
  GameActionsEnum,
  string | undefined
> {
  public readonly type = GameActionsEnum.GAME_STATE_SET_GAME_ID;
}
export class SetReconnectTokenAction extends ActionWithPayload<
  GameActionsEnum,
  string | undefined
> {
  public readonly type = GameActionsEnum.GAME_STATE_SET_RECONNECT_TOKEN;
}

export class AddVisiblePlayAction extends ActionWithPayload<
  GameActionsEnum,
  IVisiblePlay
> {
  public readonly type = GameActionsEnum.GAME_STATE_ADD_VISIBLE_PLAY;
}
export class SetVisibleGameStateAction extends ActionWithPayload<
  GameActionsEnum,
  IVisibleGameState | undefined
> {
  public readonly type = GameActionsEnum.GAME_STATE_SET_VISIBLE_STATE;
}

export class ClearAllGameStateAction extends Action<GameActionsEnum> {
  public readonly type = GameActionsEnum.GAME_STATE_CLEAR_ALL;
}

export type SetGameStateActions =
  | SetPlayerIdAction
  | SetGamedIdActionAction
  | SetVisibleGameStateAction
  | SetReconnectTokenAction
  | AddVisiblePlayAction
  | ClearAllGameStateAction;

// We just make Dispatch available in our error callback
const easyError = (dispatch: DispatchWithMiddleWare) => (e: unknown) =>
  dispatch(appActions.addError(e));

function attemptPlay(play: ISourcedCard[]): IThunk {
  return (dispatch, getState, { gameService, clientGameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_ATTEMPT_PLAY });
    const { gameId, visibleGameState } = getState();
    if (!gameId) {
      throw new Error('No Game Id');
    }
    if (visibleGameState) {
      dispatch(
        setVisibleGameState(
          clientGameService.getPredictedGameState(visibleGameState, play)
        )
      );
    }
    return gameService.attemptPlay(gameId, play).catch(async (e) => {
      easyError(dispatch)(e);

      dispatch(setVisibleGameState(await gameService.getCurrentState(gameId)));
    });
  };
}

function attemptReorderHandCards(cards: ICard[]): IThunk {
  return (dispatch, getState, { gameService, clientGameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_ATTEMPT_REORDER_HAND_CARDS });
    const { gameId, visibleGameState } = getState();
    if (!gameId) {
      throw new Error('No Game Id');
    }
    if (visibleGameState) {
      dispatch(
        setVisibleGameState(
          clientGameService.getPredictedGameStateAfterReorderingHandCards(
            visibleGameState,
            cards
          )
        )
      );
    }
    return gameService
      .attempReorderHandCards(gameId, cards)
      .catch(async (e) => {
        easyError(dispatch)(e);

        dispatch(
          setVisibleGameState(await gameService.getCurrentState(gameId))
        );
      });
  };
}

function attemptSwap(card1: ISourcedCard, card2: ISourcedCard): IThunk {
  return async (dispatch, getState, { gameService, clientGameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_ATTEMPT_SWAP });
    const { gameId, visibleGameState } = getState();
    if (!gameId) {
      throw new Error('No Game Id');
    }
    if (visibleGameState) {
      dispatch(
        setVisibleGameState(
          clientGameService.getPredictedGameStateAfterSwap(
            visibleGameState,
            card1,
            card2
          )
        )
      );
    }
    await gameService.attemptSwap(gameId, card1, card2).catch(async (e) => {
      easyError(dispatch)(e);
      dispatch(setVisibleGameState(await gameService.getCurrentState(gameId)));
    });
  };
}

function attemptPickUp(): IThunk {
  return (dispatch, getState, { gameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_ATTEMPT_PICKUP });
    const { gameId } = getState();
    if (!gameId) {
      throw new Error('No Game Id');
    }
    return gameService.attemptPickup(gameId).catch(easyError(dispatch));
  };
}

function createGame(
  config: IGameConfig,
  gameReadyCallback: (gameId: string) => Promise<void>
): IThunk {
  return (dispatch, __, { gameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_CREATE });
    dispatch(appActions.setGameConfig(config));
    return gameService
      .create(config)
      .then(gameReadyCallback)
      .catch(easyError(dispatch));
  };
}

function joinGame(gameId: string, name: string): IThunk {
  return (dispatch, getState, { gameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_JOIN });
    return gameService
      .joinGame(gameId, name)
      .then((joinGameResult) => {
        dispatch(gameActions.setPlayerId(joinGameResult.playerIndex));
        dispatch(gameActions.setReconnectToken(joinGameResult.reconnectToken));
        dispatch(gameActions.setGameId(gameId));
      })
      .catch(easyError(dispatch));
  };
}

function leaveGame(gameId: string): IThunk {
  return (dispatch, getState, { gameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_LEAVE });

    dispatch(gameActions.clearAllGameState());
    return gameService.leaveGame(gameId).catch(easyError(dispatch));
  };
}

function rejoinGame(gameId: string, reconnectToken: string): IThunk {
  return async (dispatch, getState, { gameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_REJOIN });
    try {
      await gameService.rejoinGame(gameId, reconnectToken);
      const gs = await gameService.getCurrentState(gameId);
      return dispatch(gameActions.setVisibleGameState(gs));
    } catch (err) {
      easyError(dispatch)(err);
    }
  };
}

function replaceBotInGame(): IThunk {
  return async (dispatch, getState, { gameService }) => {
    dispatch({ type: GameActionsEnum.GAME_STATE_REJOIN });
    const state = getState();
    const gameId = state.currentLobby?.additionalGameProperties.currentGameId;
    const name = state.name;
    if (!gameId) {
      easyError(dispatch)(Error('Lobby not running a game yet.'));
      return;
    }
    try {
      const joinGameResult = await gameService.replaceBotInGame(gameId, name);
      dispatch(gameActions.setPlayerId(joinGameResult.playerIndex));
      dispatch(gameActions.setReconnectToken(joinGameResult.reconnectToken));
      dispatch(gameActions.setGameId(gameId));
      const gs = await gameService.getCurrentState(gameId);
      return dispatch(gameActions.setVisibleGameState(gs));
    } catch (err) {
      easyError(dispatch)(err);
    }
  };
}

function setVisibleGameState(vgs: IVisibleGameState | undefined): IThunk {
  return (dispatch, getStore) => {
    const store = getStore();

    dispatch(new SetVisibleGameStateAction(vgs));
    // Check if it is your turn and vibrate
    // eslint-disable-next-line no-mixed-operators
    if (
      !store.visibleGameState ||
      (vgs && vgs.player.isTurn && !store.visibleGameState.player.isTurn)
    ) {
      if (navigator.vibrate) {
        navigator.vibrate(100);
      }
    }
    return Promise.resolve();
  };
}

export const gameActions = {
  createGame: createGame,
  joinGame: joinGame,
  leaveGame: leaveGame,
  attemptPlay: attemptPlay,
  attemptPickUp: attemptPickUp,
  attemptSwap: attemptSwap,
  attemptReorderHandCards,
  rejoinGame: rejoinGame,
  setVisibleGameState,
  replaceBotInGame: replaceBotInGame,
  addVisiblePlay: (vp: IVisiblePlay) => new AddVisiblePlayAction(vp),
  setGameId: (id: string | undefined) => new SetGamedIdActionAction(id),
  setReconnectToken: (reconnectToken: string | undefined) =>
    new SetReconnectTokenAction(reconnectToken),
  setPlayerId: (id: number | undefined) => new SetPlayerIdAction(id),
  clearAllGameState: () => new ClearAllGameStateAction(),
};
