import { Component } from 'react';
import { loadCard } from '../CardLoader/CardLoader';
import { IUnrevealedCard } from '../../../Models/StateModels';

class OpponentHiddenCard extends Component<IUnrevealedCard> {
  public render() {
    return (
      <div
        key={this.props.cardId}
        // layoutId={this.props.cardId}
        // layout="position"
      >
        {loadCard(this.props)}
      </div>
    );
  }
}

export default OpponentHiddenCard;
