import classNames from 'classnames';
import { motion, PanInfo } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { ISourcedCard } from '../../../Models/CardModels';
import { ISourcedCardDragTarget } from '../../../Models/UIModels';
import Card from '../../General/Card/Card';
import './DraggableCard.css';

export interface IDraggableCardProps {
  card: ISourcedCard;
  onDragEnd: (info: PanInfo) => void;
  onDragging: (info: PanInfo) => void;
  setDragTarget(playAreaDragTarget: ISourcedCardDragTarget): void;
}

const DraggableCard = ({
  card,
  setDragTarget,
  onDragEnd,
  onDragging,
}: IDraggableCardProps) => {
  const [dragHovered, setDragHovered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDragTarget({
      card,
      onDragEnter: () => setDragHovered(true),
      onDragExit: () => setDragHovered(false),
      getCoords: () =>
        ref?.current
          ? {
              width: ref.current.getBoundingClientRect().width,
              height: ref.current.getBoundingClientRect().height,
              y: ref.current.getBoundingClientRect().top,
              x: ref.current.getBoundingClientRect().left,
            }
          : undefined,
    });
  }, [setDragTarget, card]);

  return (
    <motion.div
      className={classNames('card-container', { 'drag-hovered': dragHovered })}
      drag
      dragSnapToOrigin
      dragMomentum={false}
      whileDrag={{
        zIndex: 1000,
        scale: 1.1,
      }}
      onDrag={(_, info) => {
        onDragging(info);
      }}
      onDragEnd={(_, info) => {
        onDragEnd(info);
      }}
      layoutId={card.card.cardId}
      ref={ref}
    >
      <Card card={card.card} />
    </motion.div>
  );
};

export default DraggableCard;
