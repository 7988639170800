import { PanInfo } from 'framer-motion';
import { Component } from 'react';
import { groupedWhenInOrder } from '../../../functions';
import { IHandCard, ISourcedCard } from '../../../Models/CardModels';
import { ISourcedCardDragTarget } from '../../../Models/UIModels';
import DraggableCardGroup from '../DraggableCardGroup/DraggableCardGroup';

export interface IHandCardsProps {
  handCards: IHandCard[];
  onCardsDragged: (cards: ISourcedCard[], info: PanInfo) => void;
  setDragTarget(playAreaDragTarget: ISourcedCardDragTarget): void;
  draggingPlayerCards: (cards: ISourcedCard[], info: PanInfo) => void;
}

class HandCards extends Component<IHandCardsProps> {
  public render() {
    const { handCards } = this.props;
    const groupedHandCards = groupedWhenInOrder(handCards, (x) => x.card.value);
    return (
      <div className="hand-cards">
        {groupedHandCards.map((group, i) => (
          <DraggableCardGroup
            cards={group.items}
            key={group.items[0].card.cardId}
            onDragEnd={this.props.onCardsDragged}
            setDragTarget={this.props.setDragTarget}
            onDragging={this.props.draggingPlayerCards}
          />
        ))}
      </div>
    );
  }
}

export default HandCards;
