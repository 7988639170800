import { Component } from 'react';
import {
  IGameConfig,
  IGameRules,
  SpecialCardPlayMode,
} from '../../../Models/GameSetupModels';
import {
  IVisibleLobby,
  IAdditionalGameProperties,
} from '../../../Models/LobbyModels';
import ActionBar from '../ActionBar/ActionBar';
import './Lobby.css';
import YesNoModal from '../Modal/YesNoModal';
import equal from 'fast-deep-equal/es6';

interface ILobbyProps {
  lobby: IVisibleLobby;
  leaveLobby: () => void;
  isLobbyAdmin: boolean;
  startGame: (gameConfig: IGameConfig) => void;
  previousConfig: IGameConfig;
  updateLobby: (
    name: string,
    additionalGameProperties: IAdditionalGameProperties
  ) => Promise<void>;
  replaceBotInGame: () => void;
}

interface ILobbyState {
  numberOfBots: number | '';
  ruleset: RulesetType;
  showLeaveModal: boolean;
  gameRules: IGameRules;
  advancedHidden: boolean;
  startingGame: boolean;
}
const Rulesets = {
  [`Seb's Rules`]: {
    dynamicRuleSetOptions: {
      canPickupVisibleCardsAsPartOfPlay: true,
      mustPlayIfCanPlay: true,
      mustShowHiddenFlip: true,
      numberInARowToBurn: 4,
      playAfterWinner: true,
      playAgainAfterForcingAPickup: false,
      specialCards: {
        TEN: {
          burns: true,
          playMode: SpecialCardPlayMode.CanAlwaysPlay,
          resetsThePile: false,
          switchesNextPlayToDescending: false,
          glass: false,
        },
        TWO: {
          burns: false,
          playMode: SpecialCardPlayMode.CanAlwaysPlay,
          resetsThePile: true,
          switchesNextPlayToDescending: false,
          glass: false,
        },
        EIGHT: {
          burns: false,
          playMode: SpecialCardPlayMode.Unmodified,
          resetsThePile: false,
          switchesNextPlayToDescending: true,
          glass: false,
        },
      },
    },
    numberOfHandCards: 3,
    numberOfHiddenCards: 3,
    numberOfVisibleCards: 3,
  },
  [`Nan's Rules`]: {
    dynamicRuleSetOptions: {
      canPickupVisibleCardsAsPartOfPlay: false,
      mustPlayIfCanPlay: true,
      mustShowHiddenFlip: false,
      numberInARowToBurn: 4,
      playAfterWinner: false,
      playAgainAfterForcingAPickup: true,
      specialCards: {
        TEN: {
          burns: true,
          playMode: SpecialCardPlayMode.CanAlwaysPlayExceptOnDescending,
          resetsThePile: false,
          switchesNextPlayToDescending: false,
          glass: false,
        },
        TWO: {
          burns: false,
          playMode: SpecialCardPlayMode.CanAlwaysPlay,
          resetsThePile: true,
          switchesNextPlayToDescending: false,
          glass: false,
        },
        SEVEN: {
          burns: false,
          playMode: SpecialCardPlayMode.Unmodified,
          resetsThePile: false,
          switchesNextPlayToDescending: true,
          glass: false,
        },
        THREE: {
          burns: false,
          playMode: SpecialCardPlayMode.CanAlwaysPlay,
          resetsThePile: false,
          switchesNextPlayToDescending: false,
          glass: true,
        },
      },
    },
    numberOfHandCards: 3,
    numberOfHiddenCards: 3,
    numberOfVisibleCards: 3,
  },
};

type RulesetType = keyof typeof Rulesets | 'Custom';
class Lobby extends Component<ILobbyProps, ILobbyState> {
  startingGameTimout: NodeJS.Timeout | undefined;
  constructor(props: ILobbyProps) {
    super(props);
    this.state = {
      numberOfBots: props.lobby.additionalGameProperties.numberOfBots,
      showLeaveModal: false,
      gameRules: props.previousConfig.gameRules,
      advancedHidden: true,
      ruleset: this.determineRuleset(props.previousConfig.gameRules),
      startingGame: false,
    };
  }

  private determineRuleset(rules: IGameRules): RulesetType {
    if (equal(rules, Rulesets[`Seb's Rules`])) {
      return `Seb's Rules`;
    }
    if (equal(rules, Rulesets[`Nan's Rules`])) {
      return `Nan's Rules`;
    }
    return `Custom`;
  }

  public render() {
    const { lobby, isLobbyAdmin, leaveLobby } = this.props;
    return (
      <>
        <ActionBar
          backButtonClicked={this.attemptLeaveLobby}
          title={lobby.name}
        />
        <div className="lobby">
          {this.renderPlayers(lobby)}
          {this.renderAdminUi(isLobbyAdmin)}
        </div>
        {this.state.showLeaveModal && (
          <YesNoModal
            header="Leave Game"
            message="You are the game leader, are you sure you want to leave?"
            onYes={leaveLobby}
            onNo={this.cancelModal}
          />
        )}
      </>
    );
  }

  private renderPlayers(lobby: IVisibleLobby) {
    return (
      <div className="players">
        <h3>Players</h3>
        {lobby.players.map((x, i) => (
          <p key={i}>{x.name}</p>
        ))}
      </div>
    );
  }

  private renderReplaceBot(lobby: IVisibleLobby) {
    if (
      !lobby.additionalGameProperties.currentGameId ||
      lobby.additionalGameProperties.numberOfBots === 0
    ) {
      return;
    }
    return (
      <div className="replace-bot">
        <button className="btn accent" onClick={this.onReplaceBotClicked}>
          <span>Replace Bot in Running Game</span>
        </button>
      </div>
    );
  }

  private renderAdminUi = (isAdmin: boolean) => {
    const valueSource = this.props.isLobbyAdmin
      ? { ...this.state }
      : { ...this.props.lobby.additionalGameProperties };
    const { advancedHidden } = this.state;
    valueSource.gameRules = valueSource.gameRules || this.state.gameRules;
    const { lobby } = this.props;
    const disabled = this.props.isLobbyAdmin ? undefined : true;
    return (
      <form className="game-config" onSubmit={this.onStartGameClicked}>
        <h3>Game Config</h3>
        <div className="num-bots-input">
          <label htmlFor="num-bots">Number of Bots</label>
          {this.renderNumBotsInput(isAdmin)}
        </div>
        <div className="ruleset-selection">
          <label htmlFor="ruleset-selection">Rules</label>
          {this.renderRuleset(isAdmin)}
        </div>
        <button className="btn accent" onClick={this.toggleAdvanced}>
          <span>Advanced</span>
        </button>
        <div className="advanced-container" hidden={advancedHidden}>
          <div className="num-hand-cards">
            <label htmlFor="num-hand-cards">Number of Hand Cards</label>
            <input
              type="number"
              name="num-hand-cards"
              onChange={this.onNumCardsChange}
              data-field="numberOfHandCards"
              value={valueSource.gameRules.numberOfHandCards}
              min="0"
              disabled={disabled}
            />
          </div>
          <div className="num-table-cards">
            <label htmlFor="num-table-cards">Number of Table Cards</label>
            <input
              type="number"
              name="num-hand-cards"
              data-field="numberOfVisibleCards"
              onChange={this.onNumCardsChange}
              value={valueSource.gameRules.numberOfVisibleCards}
              min="0"
              disabled={disabled}
            />
          </div>
          <div className="num-hidden-cards">
            <label htmlFor="num-hidden-cards">Number of Hidden Cards</label>
            <input
              type="number"
              name="num-hand-cards"
              data-field="numberOfHiddenCards"
              onChange={this.onNumCardsChange}
              value={valueSource.gameRules.numberOfHiddenCards}
              min="0"
              disabled={disabled}
            />
          </div>
          <div className="check-container">
            <label htmlFor="must-play-can-play">Must Play Can Play</label>
            <input
              name="must-play-can-play"
              type="checkbox"
              checked={
                valueSource.gameRules.dynamicRuleSetOptions.mustPlayIfCanPlay
              }
              onChange={this.onDynamicRuleSetCheckboxChange}
              data-field="mustPlayIfCanPlay"
              disabled={disabled}
            />
          </div>
          <div className="check-container">
            <label htmlFor="must-show-failed-hidden-flip">
              Show failed hidden flips
            </label>
            <input
              name="must-show-failed-hidden-flip"
              type="checkbox"
              checked={
                valueSource.gameRules.dynamicRuleSetOptions.mustShowHiddenFlip
              }
              onChange={this.onDynamicRuleSetCheckboxChange}
              data-field="mustShowHiddenFlip"
              disabled={disabled}
            />
          </div>
          <div className="check-container">
            <label htmlFor="play-after-winner">Play after winner</label>
            <input
              name="play-after-winner"
              type="checkbox"
              checked={
                valueSource.gameRules.dynamicRuleSetOptions.playAfterWinner
              }
              onChange={this.onDynamicRuleSetCheckboxChange}
              data-field="playAfterWinner"
              disabled={disabled}
            />
          </div>
          <div className="check-container">
            <label htmlFor="can-pickup-table-cards">
              Can pickup table cards when not playable
            </label>
            <input
              name="can-pickup-table-cards"
              type="checkbox"
              checked={
                valueSource.gameRules.dynamicRuleSetOptions
                  .canPickupVisibleCardsAsPartOfPlay
              }
              onChange={this.onDynamicRuleSetCheckboxChange}
              data-field="canPickupVisibleCardsAsPartOfPlay"
              disabled={disabled}
            />
          </div>
          <div className="check-container">
            <label htmlFor="play-again-after-forcing-a-pickup">
              Play again after forcing a pickup
            </label>
            <input
              name="play-again-after-forcing-a-pickup"
              type="checkbox"
              checked={
                valueSource.gameRules.dynamicRuleSetOptions
                  .playAgainAfterForcingAPickup
              }
              onChange={this.onDynamicRuleSetCheckboxChange}
              data-field="playAgainAfterForcingAPickup"
              disabled={disabled}
            />
          </div>
        </div>
        {this.renderAdminStartButton(isAdmin)}
        {this.renderReplaceBot(lobby)}
      </form>
    );
  };

  private renderNumBotsInput(isAdmin: boolean) {
    if (isAdmin) {
      const { numberOfBots } = this.state;
      return (
        <input
          type="number"
          name="num-bots"
          onChange={this.onNumBotsChange}
          value={numberOfBots}
          min="0"
        />
      );
    }

    return (
      <input
        type="number"
        name="num-bots"
        value={this.props.lobby.additionalGameProperties.numberOfBots}
        disabled={true}
      />
    );
  }

  private renderRuleset(isAdmin: boolean) {
    const { ruleset } = this.state;
    if (isAdmin) {
      return (
        <select
          name="ruleset-selection"
          onChange={this.onRulesetChange}
          value={ruleset}
        >
          <option value="Seb's Rules">Seb's Rules</option>
          <option value="Nan's Rules">Nan's Rules</option>
          <option value="Custom">Custom</option>
        </select>
      );
    }

    return (
      <select name="ruleset-selection" disabled={true} value={ruleset}>
        <option value="Seb's Rules">Seb's Rules</option>
        <option value="Nan's Rules">Nan's Rules</option>
        <option value="Custom">Custom</option>
      </select>
    );
  }

  toggleAdvanced = (e: { preventDefault(): void }) => {
    e.preventDefault();
    this.setState({ advancedHidden: !this.state.advancedHidden });
  };

  private renderAdminStartButton(isAdmin: boolean) {
    if (!isAdmin) {
      return;
    }
    return (
      <div className="start-game-btn">
        <button
          className="btn "
          disabled={
            this.state.startingGame ||
            this.props.lobby.players.length +
              this.props.lobby.additionalGameProperties.numberOfBots <=
              1
          }
        >
          <span>
            {this.state.startingGame ? 'Starting Game...' : 'Start Game'}
          </span>
        </button>
      </div>
    );
  }

  private onDynamicRuleSetCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const gameRules = {
      ...this.state.gameRules,
      dynamicRuleSetOptions: {
        ...this.state.gameRules.dynamicRuleSetOptions,
        [event.target.getAttribute('data-field') || '']: event.target.checked,
      },
    };
    const newState: ILobbyState = {
      ...this.state,
      ruleset: this.determineRuleset(gameRules),
      gameRules,
    };
    this.setState(newState);
    this.props.updateLobby(this.props.lobby.name, {
      numberOfBots: this.state.numberOfBots || 0,
      gameRules: newState.gameRules,
    });
  };

  private onNumBotsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let numberOfBots: number | '' = parseInt(event.target.value, 10);
    if (isNaN(numberOfBots)) {
      numberOfBots = '';
    }
    this.setState({ numberOfBots });
    this.props.updateLobby(this.props.lobby.name, {
      numberOfBots: numberOfBots || 0,
      gameRules: this.state.gameRules,
    });
  };

  private onNumCardsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let numberOfCards: number | '' = parseInt(event.target.value, 10);
    if (isNaN(numberOfCards)) {
      numberOfCards = '';
    }
    const gameRules = {
      ...this.state.gameRules,
      [event.target.getAttribute('data-field') || '']: numberOfCards,
    };
    const newState: ILobbyState = {
      ...this.state,
      ruleset: this.determineRuleset(gameRules),
      gameRules,
    };
    this.setState(newState);
    this.props.updateLobby(this.props.lobby.name, {
      numberOfBots: newState.numberOfBots || 0,
      gameRules: newState.gameRules,
    });
  };

  private onRulesetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const ruleset: RulesetType = event.target.value as RulesetType;
    this.setState({ ruleset });
    if (ruleset !== 'Custom') {
      const rulesetObj = Rulesets[ruleset];
      this.setState({
        gameRules: {
          ...rulesetObj,
          dynamicRuleSetOptions: {
            ...rulesetObj.dynamicRuleSetOptions,
            specialCards: { ...rulesetObj.dynamicRuleSetOptions.specialCards },
          },
        },
      });
      this.props.updateLobby(this.props.lobby.name, {
        numberOfBots: this.state.numberOfBots || 0,
        gameRules: this.state.gameRules,
      });
    }
  };

  private onStartGameClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.startingGame) {
      return;
    }
    if (
      this.props.isLobbyAdmin &&
      this.props.lobby.players.length +
        this.props.lobby.additionalGameProperties.numberOfBots >
        1
    ) {
      this.setState({ startingGame: true });
      this.startingGameTimout = setTimeout(
        () => this.setState({ startingGame: false }),
        5000
      );
      const numberOfBots = this.state.numberOfBots || 0;
      this.props.startGame({
        numberOfPlayers: this.props.lobby.players.length,
        numberOfBots,
        gameRules: this.state.gameRules,
      });
    }
  };

  private onReplaceBotClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      this.state.startingGame ||
      !this.props.lobby.additionalGameProperties.currentGameId
    ) {
      return;
    }
    this.setState({ startingGame: true });
    this.startingGameTimout = setTimeout(
      () => this.setState({ startingGame: false }),
      5000
    );
    this.props.replaceBotInGame();
  };

  private cancelModal = () => {
    this.setState({ showLeaveModal: false });
  };

  private attemptLeaveLobby = () => {
    const { isLobbyAdmin, leaveLobby } = this.props;
    if (isLobbyAdmin) {
      this.setState({ showLeaveModal: true });
    } else {
      leaveLobby();
    }
  };

  componentWillUnmount(): void {
    if (this.startingGameTimout) {
      clearTimeout(this.startingGameTimout);
    }
  }
}

export default Lobby;
