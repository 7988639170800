import { Component } from 'react';
import { ILeaderboard } from '../../../Models/LeaderboardModels';
import ActionBar from '../ActionBar/ActionBar';
import './Leaderboard.css';

interface ILeaderboardProps {
  leaderboard: ILeaderboard;
  refreshLeaderboard: () => Promise<void>;
  onBackButtonClick: () => void;
}

export class Leaderboard extends Component<ILeaderboardProps> {
  render() {
    return (
      <>
        <ActionBar
          backButtonClicked={this.props.onBackButtonClick}
          title="Leaderboard"
        />
        <div className="leaderboard">
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Points</th>
                <th>Wins</th>
                <th>Losses</th>
                <th>Forfeits</th>
                <th>Bot</th>
              </tr>
            </thead>
            <tbody>
              {this.props.leaderboard.entries.map((entry, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{entry.name}</td>
                  <td>{entry.points}</td>
                  <td>{entry.wins}</td>
                  <td>{entry.losses}</td>
                  <td>{entry.forfeits}</td>
                  <td>{entry.isBot.toString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.props.refreshLeaderboard();
  }
}
