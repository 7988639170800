import { Component } from 'react';
import { IVisibleGameState } from '../../../Models/StateModels';
import './GameOver.css';
import { getOrdinal } from '../../../functions';

interface IGameOverProps {
  visibleGameState: IVisibleGameState;
  leaveGame: () => Promise<void>;
}

class GameOver extends Component<IGameOverProps> {
  render() {
    const { leaveGame } = this.props;
    const { opponents, player } = this.props.visibleGameState;
    const allPlayers = [...opponents, player];
    allPlayers.sort(
      (a, b) => (a.place || allPlayers.length) - (b.place || allPlayers.length)
    );
    const playerNames = allPlayers.map((p, i) => (
      <span className="game-over-position" key={i}>
        <span className="game-over-ordinal">
          {i + 1}
          <small>{getOrdinal(i + 1)}</small>.
        </span>
        <span className="game-over-player-name">{p.name}</span>
      </span>
    ));
    return (
      <div className="game-over">
        <div className="game-over-box">
          <h1>GAME OVER</h1>
          <div className="game-over-positions">{playerNames}</div>
          <button
            className="btn btn-primary game-over-leave-btn"
            onClick={leaveGame}
          >
            <span>Leave Game</span>
          </button>
        </div>
      </div>
    );
  }
}

export default GameOver;
