import * as signalR from '@microsoft/signalr';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { register } from './registerServiceWorker';

import {
  persistReducer,
  persistStore,
  PersistConfig,
  PersistedState,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { appActions } from './Actions/AppActions';
import { gameActions } from './Actions/GameActions';
import { lobbyActions } from './Actions/LobbyActions';
import App from './App';
import config from './config';
import { Action, IServices } from './definitions';
import './index.css';
import { monoReducer, IStore } from './Reducers';
import { GameService } from './Services/GameService';
import { LeaderboardService } from './Services/LeaderboardService';
import { LobbyService } from './Services/LobbyService';
import WebFont from 'webfontloader';
import { generateUUID } from './functions';
import { ClientGameService } from './Services/ClientGameService';
import { Dispatch, StrictMode } from 'react';

const userId = localStorage.getItem('stored-user-id') || generateUUID();
localStorage.setItem('stored-user-id', userId);
const gameConnection = new signalR.HubConnectionBuilder()
  .withUrl(config.apiGateway.URL + '/TensAndTwos/Game', {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
    accessTokenFactory: () => userId,
  })
  .withHubProtocol(new signalR.JsonHubProtocol())
  .build();

const lobbyConnection = new signalR.HubConnectionBuilder()
  .withUrl(config.apiGateway.URL + '/Shared/Lobby', {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
    accessTokenFactory: () => userId,
  })
  .withHubProtocol(new signalR.JsonHubProtocol())
  .build();

const leaderboardService = new LeaderboardService(
  config.apiGateway.URL + '/TensAndTwos/Leaderboard'
);
const gameService = new GameService(gameConnection);
const clientGameService = new ClientGameService();
const lobbyService = new LobbyService(lobbyConnection);

const services: IServices = {
  gameService,
  lobbyService,
  clientGameService,
  leaderboardService,
};

// custom middleware for transforming Typed Actions into plain action
// Redux enforces !isPlainObject(action)
const typedToPlain =
  (_: unknown) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    if (action instanceof Action) {
      next(Object.assign({}, action));
    } else {
      next(action);
    }
  };

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig: PersistConfig<IStore> = {
  key: 'root',
  version: 1,
  storage,
  migrate: (oldState: PersistedState | IStore, version: number) => {
    const castedState = { ...oldState } as IStore | undefined;
    if (
      castedState &&
      (!castedState.gameConfig || !castedState.gameConfig.gameRules)
    ) {
      castedState.gameConfig = monoReducer(undefined, null).gameConfig;
    }

    if (castedState && !castedState.messages) {
      castedState.messages = [];
    }

    return Promise.resolve({ ...castedState } as PersistedState);
  },
  blacklist: [
    'visibleGameState',
    'errors',
    'currentLobby',
    'lobbyConnectionState',
    'gameConnectionState',
    'availableLobbies',
    'dragTargets',
  ] as (keyof IStore)[],
};

const withExtraArgumentTyped = <E, S>(e: E): ThunkMiddleware<S, AnyAction, E> =>
  thunk.withExtraArgument(e);

const persistedReducer = persistReducer(persistConfig, monoReducer);
const store = createStore(
  persistedReducer,
  monoReducer(
    undefined,
    appActions.setLobbyConnectionState({
      connected: false,
      numRetries: 0,
      waitFactor: 200,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any,
  composeEnhancers(
    applyMiddleware(
      withExtraArgumentTyped<IServices, IStore>(services),
      typedToPlain
    )
  )
);

// Update the store when we get updates
gameService.subscribe(
  (vgs) => store.dispatch(gameActions.setVisibleGameState(vgs)),
  (vp) => store.dispatch(gameActions.addVisiblePlay(vp)),
  () =>
    store.dispatch(
      appActions.setGameConnectionState({
        connected: true,
        numRetries: 0,
        waitFactor: store.getState().gameConnectionState.waitFactor,
      })
    ),
  () =>
    store.dispatch(
      appActions.setGameConnectionState({
        connected: false,
        numRetries: store.getState().gameConnectionState.numRetries,
        waitFactor: store.getState().gameConnectionState.waitFactor,
      })
    )
);
lobbyService.subscribe(
  (gameId) => store.dispatch(lobbyActions.newGameAvailable(gameId)),
  (lobby) => store.dispatch(lobbyActions.setCurrentLobby(lobby)),
  (lobbies) => store.dispatch(lobbyActions.setAvailableLobbies(lobbies)),
  (message) => {
    store.dispatch(lobbyActions.newMessage(message));
  },
  () =>
    store.dispatch(
      appActions.setLobbyConnectionState({
        connected: true,
        numRetries: 0,
        waitFactor: store.getState().lobbyConnectionState.waitFactor,
      })
    ),
  () =>
    store.dispatch(
      appActions.setLobbyConnectionState({
        connected: false,
        numRetries: store.getState().lobbyConnectionState.numRetries,
        waitFactor: store.getState().lobbyConnectionState.waitFactor,
      })
    )
);
const persistor = persistStore(store, undefined, () => {
  store.dispatch(appActions.attemptGameReconnect());
  store.dispatch(appActions.attemptLobbyReconnect());
});
const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('No root element defined');
}
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>
);
register({
  onUpdate: () => {
    // At this point, the old content will have been purged and
    // the fresh content will have been added to the cache.
    // It's the perfect time to display a 'New content is
    // available; please refresh.' message in your web app.
    console.log('New content is available; please refresh.');
    const refreshPage = window.confirm(
      'A new update to the app has been installed in the background, would you like to reload?'
    );
    if (refreshPage) {
      window.location.reload();
    }
  },
});
WebFont.load({
  google: {
    families: ['Roboto'],
  },
});

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
