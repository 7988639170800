import { Component, createRef, RefObject } from 'react';
import { IUnrevealedCard, IVisibleOpponent } from '../../Models/StateModels';
import oneHandCardLogo from './cards1.png';
import twoHandCardLogo from './cards2.png';
import threeHandCardLogo from './cards3.png';
import fourHandCardLogo from './cards4.png';
import fiveHandCardLogo from './cards5.png';
import largeHandCardLogo from './cards8.png';
import './Opponent.css';
import OpponentTableCards from './OpponentTableCards/OpponentTableCards';
import Ephemeral from '../General/Ephemeral/Ephemeral';
import { IChatMessage } from '../../Models/LobbyModels';
import scrollIntoView from 'scroll-into-view';
import Card from '../General/Card/Card';

export interface IOpponentProps {
  opponent: IVisibleOpponent;
  lastSentMessage: IChatMessage | undefined;
}

const handCardToImage = [
  oneHandCardLogo,
  twoHandCardLogo,
  threeHandCardLogo,
  fourHandCardLogo,
  fiveHandCardLogo,
];

class Opponent extends Component<IOpponentProps> {
  private myRef: RefObject<HTMLDivElement>;
  constructor(props: IOpponentProps) {
    super(props);
    this.myRef = createRef<HTMLDivElement>();
  }
  public render() {
    const { opponent, lastSentMessage } = this.props;
    if (opponent.isTurn && this.myRef.current) {
      scrollIntoView(this.myRef.current, {
        time: 200,
        align: { lockY: true },
      });
    }
    return (
      <div
        ref={this.myRef}
        className={'opponent' + (opponent.isTurn ? ' is-turn' : '')}
      >
        <p>{opponent.name}</p>
        <div className="opponent-cards">
          {this.getOpponentHandCards(opponent.handCards)}
          <OpponentTableCards
            visibleCards={opponent.visibleCards}
            hiddenCards={opponent.hiddenCards}
          />
        </div>
        <Ephemeral
          id={lastSentMessage}
          message={lastSentMessage && lastSentMessage.message}
        />
      </div>
    );
  }

  private getOpponentHandCards = (handCards: IUnrevealedCard[]) => {
    const numberOfHandCards = handCards.length;
    let handCardLogo = largeHandCardLogo;
    if (numberOfHandCards <= 5) {
      handCardLogo = handCardToImage[numberOfHandCards] || largeHandCardLogo;
    }

    return (
      <div className="opponent-hand-cards">
        <img
          style={{ opacity: numberOfHandCards === 0 ? 0 : 1 }}
          alt={`${numberOfHandCards} Hand cards`}
          className="hand-card-logo"
          src={handCardLogo}
        />
        {numberOfHandCards === 0 ? null : <p>{numberOfHandCards}</p>}
        {handCards.map((hc) => (
          <Card card={hc} none={true} key={hc.cardId} />
        ))}
      </div>
    );
  };
}

export default Opponent;
