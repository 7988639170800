import { IUnrevealedCard } from '../../../Models/StateModels';
import Card from '../Card/Card';

const BurntCards = ({ burntCards }: { burntCards: IUnrevealedCard[] }) => {
  return (
    <div
      className="burnt-cards"
      style={{
        position: 'absolute',
        opacity: 1,
        width: '100%',
        height: '100%',
        left: '110%',
      }}
    >
      {burntCards.map((bc) => (
        <Card none={true} card={bc} key={bc.cardId} />
      ))}
    </div>
  );
};

export default BurntCards;
