import { Component } from 'react';
import { IGameConfig } from '../../../Models/GameSetupModels';
import Lobby from '../Lobby/Lobby';
import LobbyList from '../LobbyList/LobbyList';
import HomeScreen from '../HomeScreen/HomeScreen';
import './StartGame.css';
import {
  IVisibleLobby,
  IAdditionalGameProperties,
} from '../../../Models/LobbyModels';
import { Leaderboard } from '../Leaderboard/Leaderboard';
import { ILeaderboard } from '../../../Models/LeaderboardModels';

interface IStartGameProps {
  updateBeforeGameScreen: (screen: Screen) => void;
  setName: (name: string) => void;
  createAndJoinLobby: (lobbyName: string, userName: string) => Promise<void>;
  leaveLobby: () => void;
  joinLobby: (
    lobbyId: string,
    userId: string,
    userName: string
  ) => Promise<void>;
  startLobbyGame: (config: IGameConfig) => Promise<void>;
  updateLobby: (
    name: string,
    additionalGameProperties: IAdditionalGameProperties
  ) => Promise<void>;
  refreshLobbies: () => Promise<void>;
  refreshLeaderboard: () => Promise<void>;
  replaceBotInGame: () => void;
  gameId: string | undefined;
  currentScreen: Screen;
  name: string;
  userId: string;
  isLobbyAdmin: boolean;
  lobbyList: IVisibleLobby[];
  currentLobby: IVisibleLobby | undefined;
  gameConfig: IGameConfig;
  leaderboard: ILeaderboard;
}

export enum Screen {
  HOME,
  LOBBY_LIST,
  LEADERBOARD,
}

class StartGame extends Component<IStartGameProps> {
  public render() {
    return <div className="start-game">{this.getScreenElement()}</div>;
  }

  private getScreenElement = () => {
    const {
      currentLobby,
      currentScreen,
      name,
      lobbyList,
      refreshLobbies,
    } = this.props;
    if (currentLobby) {
      return (
        <Lobby
          previousConfig={this.props.gameConfig}
          leaveLobby={this.props.leaveLobby}
          isLobbyAdmin={this.props.isLobbyAdmin}
          lobby={currentLobby}
          startGame={this.props.startLobbyGame}
          updateLobby={this.props.updateLobby}
          replaceBotInGame={this.props.replaceBotInGame}
        />
      );
    }
    switch (currentScreen) {
      case Screen.HOME:
        return (
          <HomeScreen
            previousName={name}
            viewLobbiesClicked={this.updateWithScreen(Screen.LOBBY_LIST)}
          />
        );
      case Screen.LOBBY_LIST:
        return (
          <LobbyList
            createAndJoinLobby={this.createAndJoinLobby}
            lobbyList={lobbyList}
            joinLobby={this.joinLobby}
            refreshLobbies={refreshLobbies}
            onBackButtonClick={this.onBackButtonClick}
            playerName={name}
            goToLeaderboard={() =>
              this.props.updateBeforeGameScreen(Screen.LEADERBOARD)
            }
          />
        );
      case Screen.LEADERBOARD:
        return (
          <Leaderboard
            leaderboard={this.props.leaderboard}
            refreshLeaderboard={this.props.refreshLeaderboard}
            onBackButtonClick={() =>
              this.props.updateBeforeGameScreen(Screen.LOBBY_LIST)
            }
          />
        );
      default:
        const n: never = currentScreen;
        throw new Error(`unimplemented ${n}`);
    }
  };

  private updateWithScreen = (screen: Screen) => (newName: string) => {
    const { updateBeforeGameScreen, setName } = this.props;
    setName(newName);
    updateBeforeGameScreen(screen);
  };

  private createAndJoinLobby = (lobbyName: string) => {
    return this.props.createAndJoinLobby(lobbyName, this.props.name);
  };

  private joinLobby = (lobbyId: string) => {
    return this.props.joinLobby(lobbyId, this.props.userId, this.props.name);
  };

  private onBackButtonClick = () => {
    this.props.updateBeforeGameScreen(Screen.HOME);
  };
}

export default StartGame;
