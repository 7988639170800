import { Component } from 'react';
import { ICard, ISourcedCard } from '../../../Models/CardModels';
import { IVisibleGameState, IVisiblePlay } from '../../../Models/StateModels';
import './GameScreen.css';
import classNames from 'classnames';
import Chat from '../Chat/Chat';
import GameState from '../GameState/GameState';
import { IChatMessage, IVisibleLobby } from '../../../Models/LobbyModels';
import { IDragTarget, ISourcedCardDragTarget } from '../../../Models/UIModels';
import { IDragTargetState } from '../../../Reducers';

interface IGameScreenProps {
  visibleGameState: IVisibleGameState;
  playerId: number;
  playHistory: IVisiblePlay[];
  dragTargets: IDragTargetState;
  tryPlay: (play: ISourcedCard[]) => Promise<void>;
  leaveGame: () => Promise<void>;
  trySwap: (card1: ISourcedCard, card2: ISourcedCard) => Promise<void>;
  tryPickup: () => void;
  tryReorderHandCards: (cards: ICard[]) => Promise<void>;
  handleError: (e: Error) => void;
  setPlayAreaDragTarget(playAreaDragTarget: IDragTarget): void;
  setSourcedCardDragTarget(dragTarget: ISourcedCardDragTarget): void;

  sendMessage: (message: string) => void;

  lobby: IVisibleLobby | undefined;
  messages: IChatMessage[];
}

interface IGameScreenState {
  tab: 'game' | 'chat' | 'settings';
}

class GameScreen extends Component<IGameScreenProps, IGameScreenState> {
  state: IGameScreenState = {
    tab: 'game',
  };
  public render() {
    return (
      <article className="game-screen">
        <div className="game-top">
          <button
            onClick={this.switchToGameTab}
            className={classNames('game-tab-btn', {
              active: this.isActive('game'),
            })}
          >
            Game
          </button>
          <button
            onClick={this.switchToChatTab}
            className={classNames('chat-tab-btn', {
              active: this.isActive('chat'),
            })}
          >
            Chat
          </button>
          <button
            className={classNames('settings-tab-btn', {
              active: this.isActive('settings'),
            })}
            onClick={this.switchToSettingsTab}
          >
            Settings
          </button>
        </div>
        <article className="tab-content">{this.renderActiveTab()}</article>
      </article>
    );
  }

  renderActiveTab = () => {
    return (
      <>
        <div
          className={classNames('tab', 'game-tab', {
            'inactive-tab': this.state.tab !== 'game',
          })}
        >
          <GameState {...this.props} />
        </div>
        <div
          className={classNames('tab', 'chat-tab', {
            'inactive-tab': this.state.tab !== 'chat',
          })}
        >
          {this.renderChatTab()}
        </div>
        <div
          className={classNames('tab', 'settings-tab', {
            'inactive-tab': this.state.tab !== 'settings',
          })}
        >
          {this.renderSettingsTab()}
        </div>
      </>
    );
  };

  renderChatTab = () => {
    const { lobby, messages } = this.props;
    if (lobby) {
      return (
        <Chat
          lobby={lobby}
          messages={messages}
          sendMessage={this.sendMessage}
        />
      );
    }
  };

  renderSettingsTab = () => {
    return (
      <button className="btn" onClick={this.props.leaveGame}>
        <span>Leave Game</span>
      </button>
    );
  };

  switchToGameTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget) e.currentTarget.blur();
    this.setState({ tab: 'game' });
  };
  switchToChatTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget) e.currentTarget.blur();
    this.setState({ tab: 'chat' });
  };
  switchToSettingsTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget) e.currentTarget.blur();
    this.setState({ tab: 'settings' });
  };

  isActive = (tab: 'game' | 'chat' | 'settings') => {
    return this.state.tab === tab;
  };

  sendMessage = (message: string) => {
    this.props.sendMessage(message);
    if (message.toLowerCase() === '/leave') {
      this.props.leaveGame();
      return;
    }
  };
}

export default GameScreen;
