import { CardValue } from './CardModels';

export interface IGameConfig {
  numberOfPlayers: number;
  numberOfBots: number;

  gameRules: IGameRules;
}

export interface IGameRules {
  numberOfHandCards: number;
  numberOfHiddenCards: number;
  numberOfVisibleCards: number;
  dynamicRuleSetOptions: IDynamicRuleSetOptions;
}

export interface IJoinGameResult {
  playerIndex: number;
  reconnectToken: string;
}
export interface IDynamicRuleSetOptions {
  canPickupVisibleCardsAsPartOfPlay: boolean;
  playAgainAfterForcingAPickup: boolean;
  specialCards: { [card in keyof typeof CardValue]?: ISpecialCardOptions };
  mustPlayIfCanPlay: boolean;
  mustShowHiddenFlip: boolean;
  playAfterWinner: boolean;
  numberInARowToBurn: number;
}
export enum SpecialCardPlayMode {
  Unmodified = 0,
  CanAlwaysPlay = 1,
  CanAlwaysPlayExceptOnDescending = 2,
}

export interface ISpecialCardOptions {
  playMode: SpecialCardPlayMode;
  switchesNextPlayToDescending: boolean;
  glass: boolean;
  resetsThePile: boolean;
  burns: boolean;
}
