import './Flame.css';
const flame = (translateX: string) => (
  <div
    className="fire-container"
    style={{ transform: 'translateX(' + translateX + ')' }}
  >
    <div className="fire">
      <div className="fire-left">
        <div className="main-fire"></div>
        <div className="particle-fire"></div>
      </div>
      <div className="fire-main">
        <div className="main-fire"></div>
        <div className="particle-fire"></div>
      </div>
      <div className="fire-right">
        <div className="main-fire"></div>
        <div className="particle-fire"></div>
      </div>
      <div className="fire-bottom">
        <div className="main-fire"></div>
      </div>
    </div>
  </div>
);

export default flame;
