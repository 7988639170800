import { HubConnection } from '@microsoft/signalr';
import {
  ICreateLobbyResult,
  IVisibleLobby,
  ICreateLobbyArgs,
  IUpdateLobbyArgs,
  IChatMessage,
  ISignalRChatMessage,
} from '../Models/LobbyModels';

export class LobbyService {
  private connected = false;
  private connecting: Promise<void> | undefined;
  private connectionCallbacks: (() => void)[] = [];
  constructor(private connection: HubConnection) {}

  public async createLobby(
    args: ICreateLobbyArgs
  ): Promise<ICreateLobbyResult> {
    await this.connect();
    return await this.connection.invoke('CreateLobby', {
      ...args,
      type: 'tensandtwos',
    });
  }

  public async updateLobby(args: IUpdateLobbyArgs): Promise<void> {
    await this.connect();
    await this.connection.invoke('UpdateLobby', args);
  }

  public async joinLobby(
    lobbyId: string,
    userName: string
  ): Promise<IVisibleLobby> {
    await this.connect();
    return await this.connection.invoke('JoinLobby', lobbyId, userName);
  }

  public async leaveLobby(lobbyId: string) {
    await this.connect();
    return await this.connection.invoke('LeaveLobby', lobbyId);
  }

  public async broadcastNewGame(
    lobbyId: string,
    adminToken: string,
    gameId: string
  ) {
    await this.connect();
    return this.connection.invoke(
      'BroadcastNewGame',
      lobbyId,
      adminToken,
      gameId
    );
  }

  listLobbies: () => Promise<IVisibleLobby[]> = async () => {
    await this.connect();
    return await this.connection.invoke('ListLobbies', 'tensandtwos');
  };

  async sendMessage(lobbyId: string, message: string): Promise<void> {
    await this.connect();
    await this.connection.invoke('SendMessage', lobbyId, message);
  }

  public subscribe = (
    newGameSubscriber: (gameId: string) => void,
    newLobbyStateSubsciber: (lobby: IVisibleLobby) => void,
    newLobbiesAvailableSubscriber: (lobbies: IVisibleLobby[]) => void,
    newMessageSubscriber: (message: IChatMessage) => void,
    onConnected: () => void,
    onDisconnected: () => void
  ) => {
    this.connection.on('newGameAvailable', newGameSubscriber);
    this.connection.on('newLobbyState', newLobbyStateSubsciber);
    this.connection.on('newLobbiesAvailable', (_, lobbies) =>
      newLobbiesAvailableSubscriber(lobbies)
    );
    this.connection.on('newChatMessage', (message: ISignalRChatMessage) => {
      newMessageSubscriber({ ...message, sentAt: new Date(message.sentAt) });
    });
    this.connection.onclose(() => {
      onDisconnected();
      this.connected = false;
    });
    this.connectionCallbacks.push(onConnected);
  };

  public connect = async () => {
    if (this.connected) {
      return;
    }
    if (!this.connecting) {
      this.connecting = this.connection.start();
      this.connecting
        .then(() => this.connectionCallbacks.forEach((f) => f()))
        .finally(() => (this.connecting = undefined));
    }
    await this.connecting;
    this.connected = true;
  };
}
