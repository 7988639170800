import { PureComponent } from 'react';
import { ICard } from '../../../Models/CardModels';
import './Card.css';
import Flame from '../Flame/Flame';
import { loadCard } from '../CardLoader/CardLoader';
import { motion } from 'framer-motion';
import { IUnrevealedCard } from '../../../Models/StateModels';

export interface ICardProps {
  card: ICard | IUnrevealedCard;
  hasFlame?: boolean;
  invisible?: boolean;
  none?: boolean;
}

class Card extends PureComponent<ICardProps> {
  public render() {
    const style = this.props.none
      ? ({ position: 'absolute', left: 0, top: 0 } as const)
      : {};
    return (
      <motion.div
        // layout="position"
        // layoutId={this.props.card.cardId}
        key={this.props.card.cardId}
        initial={{ opacity: 0 }}
        exit={{ opacity: 1 }}
        animate={{ opacity: this.props.invisible || this.props.none ? 0 : 1 }}
        style={style}
      >
        {this.props.hasFlame ? (
          <>
            {Flame('1.5vmin')}
            {Flame('7vmin')}
          </>
        ) : undefined}
        {loadCard(
          this.props.card,
          this.props.invisible || this.props.none ? 'card-invisible' : undefined
        )}
      </motion.div>
    );
  }
}

export default Card;
