import { Component } from 'react';
import Error from './Error';
import './Errors.css';

interface IErrorsProps {
  errors: string[];
  removeError: (index: string) => void;
}

class Errors extends Component<IErrorsProps> {
  public render() {
    const { errors, removeError } = this.props;
    const remove = (i: string) => () => removeError(i);
    return (
      <div className="errors">
        {errors.map((error, i) => (
          <Error removeError={remove(error)} error={error} key={i} />
        ))}
      </div>
    );
  }
}

export default Errors;
