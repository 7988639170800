import { PanInfo } from 'framer-motion';
import { Component } from 'react';
import {
  IHiddenCard,
  ISourcedCard,
  IVisibleCard,
} from '../../../Models/CardModels';
import { ISourcedCardDragTarget } from '../../../Models/UIModels';
import DraggableCard from '../DraggableCard/DraggableCard';
import './TableCards.css';

export interface ITableCardsProps {
  visibleCards: IVisibleCard[];
  hiddenCards: IHiddenCard[];
  onCardsDragged: (cards: ISourcedCard[], info: PanInfo) => void;
  setDragTarget(playAreaDragTarget: ISourcedCardDragTarget): void;
  draggingPlayerCards: (cards: ISourcedCard[], info: PanInfo) => void;
}

class TableCards extends Component<ITableCardsProps> {
  public render() {
    const { hiddenCards, visibleCards } = this.props;
    return (
      <div className="table-cards">
        <div className="table-cards-container">
          <div className="visible-cards">
            {visibleCards.map((c) => (
              <DraggableCard
                key={c.card.cardId}
                card={c}
                onDragEnd={(info) => this.props.onCardsDragged([c], info)}
                setDragTarget={this.props.setDragTarget}
                onDragging={(info) => this.props.draggingPlayerCards([c], info)}
              />
            ))}
          </div>
          <div className="hidden-cards">
            {hiddenCards.map((c) => (
              <DraggableCard
                key={c.card.cardId}
                card={c}
                onDragEnd={(info) => this.props.onCardsDragged([c], info)}
                setDragTarget={this.props.setDragTarget}
                onDragging={(info) => this.props.draggingPlayerCards([c], info)}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default TableCards;
