import { Component } from 'react';
import { IVisibleLobby } from '../../../Models/LobbyModels';
import { ReactComponent as Person } from './person.svg';
import './LobbyListItem.css';

interface ILobbyListItemProps {
  lobby: IVisibleLobby;
  joinLobby: (lobbyId: string) => void;
}

export default class LobbyListItem extends Component<
  ILobbyListItemProps
> {
  public render() {
    const { lobby } = this.props;
    return (
      <div className="lobby-list-item" onClick={this.joinLobby}>
        <p className="lobby-name">{lobby.name}</p>
        <span className="player-num">
          {lobby.players.length}
          <Person />
        </span>
      </div>
    );
  }

  private joinLobby = () => {
    const { lobby, joinLobby } = this.props;
    joinLobby(lobby.id);
  };
}
